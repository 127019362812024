import React from "react";
import { connect } from "react-redux";
import { userActions } from "../actions";
import LoginPageTemplate from "./LoginPageTemplate"
// import { history } from '../helpers';
import Swal from 'sweetalert2';

class TaxManagementLogin extends React.Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.state = {
      username: "",
      password: "",
      submitted: false,
      submittedForUsername: false,
      userActiveStatusIsChecked: false,
    };
  }

  handleChange = (e) => {
    // console.log("in handle change of usename",e.target.value)
    // console.log("in handle change of usename trim",e.target.value.trim())
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCheckIfUserIsActive = (e) => {
    // console.log("in handleCheckIfUserIsActive",e)
    const { username } = this.state;
    this.setState({submittedForUsername : true})

    if (username) {
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          'username': this.state.username
        })
      };
      fetch(process.env.REACT_APP_GET_ACCOUNT_STATUS, requestOptions)
      .then((Response) => Response.json())
      .then(accountSstusDetails => {
        console.log("accountSstusDetails are",accountSstusDetails)
        if(accountSstusDetails.success === true)
        {
          this.setState({ userActiveStatusIsChecked: true });
        }
        else{
          this.setState({ userActiveStatusIsChecked: false });
          Swal.fire({
            icon: 'error',
            text: "Wrong Username Or User is not active",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();  
    const { username, password } = this.state;
    // console.log("event of handle submit", this.state.username )
      const { dispatch } = this.props;
      if (username && password) {
        this.setState({ submitted: true });
        dispatch(userActions.login(username, password)); 
      }
  }

  render() {
    const { alertObject, loggingIn } = this.props;
    const { username, password, submitted, userActiveStatusIsChecked, submittedForUsername } = this.state;
    return (
      <div>
        <LoginPageTemplate
          username={username}
          password={password}
          submitted={submitted}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          alertObject={alertObject}
          loggingIn={loggingIn}
          submittedForUsername={submittedForUsername}
          userActiveStatusIsChecked={userActiveStatusIsChecked}
          handleCheckIfUserIsActive={this.handleCheckIfUserIsActive}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

export default connect(mapStateToProps)(TaxManagementLogin);
