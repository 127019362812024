import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { authHeader } from '../../helpers';
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Swal from 'sweetalert2';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Modal } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../actions";
// import useCheckSessionApi from "../../SessionValidity";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import Select from '@material-ui/core/Select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import { addSelectedCaseDetails , addSelfAssesment, addSuperCategory, addSubCategory, addSubCategoryFetched ,
  addSelectedCategoryFlag, addLastCategory, addsseInfo, addqsnList, addqsnListLevelTwo, addqsnListLevelThree,
  addYearAss } from "../../actions/updatecase.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    background: "#ffffff",
  },
  wrapper: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "5%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    // marginBottom: "10px",
    textAlign: "left",
  },
  closeIconStyle: {
    fontWeight: "500",
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "16px",
    cursor: 'pointer',
    // marginBottom: "10px",
    // textAlign: "left",
  },
  dataLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "500",
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "2px",
    // marginBottom: "10px",
    textAlign: "left",
  },
  marginRight: {
    marginRight: "4%",
  },
  textTransformNone: {
    textTransform: "none",
  },
  createStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  // customRadioButtonSuperCatergory: {
  //   border: "1px solid rgba(0, 0, 0, 0.32)",
  //   borderRadius: "6px",
  //   paddingLeft: "5px",
  //   paddingRight: "5px",
  //   marginRight: "20px",
  // },
  label: {
    fontSize: "14px",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // height: '80%',
    // marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "5%",
    boxShadow: theme.shadows[5],
  },
  userAddUpdateDelete: {
    marginTop: '2%',
    fontSize: "15px",
    color: 'black',
  },
  moreVertIcon:{
    textAlign: "right",
    cursor: "pointer",
    color: 'black',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  subHeadingLabelSelect : {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "5%",
  },
  CloseIconStyle: {
    marginLeft: "2%",
    marginTop: "2%",
    cursor: 'pointer',
  },
  formControl: {
    marginLeft: "-5%",
    marginTop: '2%',
    width: '90%',
  },
  formControlAssYear: {
    // marginLeft: "-5%",
    // marginTop: '1%',
    width: '95%',
  },
  formControlOtherContact: {
    // marginLeft: "-5%",
    marginTop: '2%',
    width: '100%',
  },
  // submit: {
  //   margin: "2%"
  // },
  datePickerStyle: {
    width: '94%',
    height: '50px',
    borderColor: 'grey',
    borderRadius: '5px',
    borderWidth: '0.5px',
    marginTop: '2%',
  },

}));

function EditCaseGeneralDetails({ handleDrawerClose, intl, handleClose, onSelectService,
  sseNameErr, sseEmailErr, sseEmailIncorrectErr, ssePhoneNoErr, ssePhoneNoNotDigitErr,
  ssePhoneNoIncorrectErr, ssePanErr, ssePanNotValidErr, sseServiceErr, sseAssesYearErr, serviceIdErr, assesmentYearErr }) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [ editItemIs, seteditItemIs ] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_edit')));

  // const [ forSelf, setforSelf ] = React.useState(JSON.stringify(editItemIs.self_assessment));
  // const [ serviceIdFinalIs, setserviceIdFinalIs ] = React.useState(editItemIs.service_id);
  const [ forSelf, setforSelf ] = React.useState();
  const [ serviceIdFinalIs, setserviceIdFinalIs ] = React.useState();

  const [ superCategoryList, setsuperCategoryList ] = React.useState([]);

  // const [ selectedServiceIdIs, setselectedServiceIdIs ] = React.useState(editItemIs.service_id);
  // const [ selectedServiceNameIs, setselectedServiceNameIs ] = React.useState(editItemIs.service);
  const [ selectedServiceIdIs, setselectedServiceIdIs ] = React.useState();
  const [ selectedServiceNameIs, setselectedServiceNameIs ] = React.useState();

  const [ superCategoryValue, setsuperCategoryValue ] = React.useState("");
  const [ subCategoryList, setsubCategoryList ] = React.useState([]);
  const [ SubCtegoryFetched, setSubCtegoryFetched ] = React.useState(false);
  const [ subCategoryValue, setsubCategoryValue ] = React.useState("");
  const [ SelectedCategoryIsCategory, setSelectedCategoryIsCategory ] = React.useState(false);
  const [ categoryDetailsLastList, setcategoryDetailsLastList ] = React.useState([]);
  const [ lastCategoryValue, setlastCategoryValue ] = React.useState("");


   const [ changeSeviceFlag, setchangeSeviceFlag ] = React.useState(false);
   const [ changeAsseEditFlag, setchangeAsseEditFlag ] = React.useState(false);
   const [ changeAsseAddFlag, setchangeAsseAddFlag ] = React.useState(false);

   const [flag , setflag] = React.useState(false);

  const [ SSEInfo, setSSEInfo] = React.useState({
    created_by: "client",
    case_id: "",
    service_id: "",
    assesse_name: "",
    assesse_id: "",
    assesse_email: "",
    assesse_pan: "",
    assesse_phone: "",
    assesse_country_code: "",
    self_assessment: "",
    assessment_year: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [ sseinfoForCondi, setsseinfoForCondi] =  React.useState({});
  const [ caseIdFetched, setcaseIdFetched] =  React.useState({});

  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const [CountryCode, setCountryCode] = React.useState("");

  const [selectedAssesmentYear, setselectedAssesmentYear] = React.useState("");

  const [ClientContactDetails, setClientContactDetails] = React.useState([]);
  const [AddNewContactFlag, setAddNewContactFlag] = React.useState();

  const [AssenameOrOtherContactSelectedForSelectFormExistingButton, setAssenameOrOtherContactSelectedForSelectFormExistingButton] = React.useState();


  var d1 = new Date();
  var pastYear = d1.getFullYear() - 10;
  d1.setFullYear(pastYear);
  console.log("d==",d1);

  var d2 = new Date();
  var pastYear = d2.getFullYear() + 2;
  d2.setFullYear(pastYear);
  console.log("d==",d2);

  const [dueDate, setdueDate] = React.useState(d2);
  const [minDate, setminDate] = React.useState(d1);


  // redux get
  const dispatch = useDispatch();
  const selectedCaseDetailsFromRedux = useSelector(state => state.updateCase.selectedCaseDetails);
  const selfAssesFromRedux = useSelector(state => state.updateCase.selfAssesment);
  const superCategoryFromRedux = useSelector(state => state.updateCase.superCategory);
  const subCategoryFromRedux = useSelector(state => state.updateCase.subCategory);
  const subCategoryFetchedFromRedux = useSelector(state => state.updateCase.subCategoryFetched);
  const selectedCategoryFlagFromRedux = useSelector(state => state.updateCase.selectedCategoryFlag);
  const lastCategoryFromRedux = useSelector(state => state.updateCase.lastCategory);
  const sseInfoFromRedux = useSelector(state => state.updateCase.sseInfo);
  const yearAssFromRedux = useSelector(state => state.updateCase.yearAss);

  // redux set
  const addSelectedCaseDetailsRedux = (selectedCaseDetails) => dispatch(addSelectedCaseDetails(selectedCaseDetails));
  const addSelfAssRedux = (selfAssesment) => dispatch(addSelfAssesment(selfAssesment));
  const addSuperCategoryRedux = (superCategory) => dispatch(addSuperCategory(superCategory));
  const addSubCategoryRedux = (subCategory) => dispatch(addSubCategory(subCategory));
  const addSubCategoryFetchedRedux = (subCategoryFetched) => dispatch(addSubCategoryFetched(subCategoryFetched));
  const addSelectedCategoryFlagRedux = (selectedCategory) => dispatch(addSelectedCategoryFlag(selectedCategory));
  const addLastCategoryRedux = (lastCategory) => dispatch(addLastCategory(lastCategory));
  const addSseInfoRedux = (sseInfo) => dispatch(addsseInfo(sseInfo));
  const addYearAssRedux = (yearAss) => dispatch(addYearAss(yearAss));

  const addqsnListRedux = (qsnList) => dispatch(addqsnList(qsnList));
  const addqsnListLevelTwoRedux = (qsnListLevelTwo) => dispatch(addqsnListLevelTwo(qsnListLevelTwo));
  const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));

  const [AssesmentYearArray, setAssesmentYearArray] = React.useState([])

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)


  const getAssesmentYear = () => {
    var year1 = new Date();
    var pastYear = year1.getFullYear() - 10;
    year1.setFullYear(pastYear);
    // console.log("d== d1.getFullYear()",year1, year1.getFullYear());
    var year1Final = year1.getFullYear()

    var year2 = new Date();
    var pastYear = year2.getFullYear() + 2;
    year2.setFullYear(pastYear);
    // console.log("d== d1.getFullYear()",year2, year2.getFullYear());
    var year2Final = year2.getFullYear()

    const value = [{value:"N/A", item:"N/A"}];
    for (let i = year1Final ; i <= year2Final ; i++) {

        const last2 = i.toString().slice(-2);
        const lastis = parseInt(last2)+1;
        // console.log("last2 DIGITS ARE",last2);
        // console.log("lastis DIGITS ARE",lastis);

        let LastValueIs = 'AY' + '-' + i.toString() + '-' +  lastis

        // console.log("LastValueIs of assesment year", LastValueIs)

        // let a = {value:i.toString(), item:i.toString()}
        let a = {value:LastValueIs.toString(), item:LastValueIs.toString()}
        
        value.push(a);
        // AssesmentYearArray.push(a)
      // console.log("value array is", value)
    }
    // console.log("value array is", value)
    setAssesmentYearArray({...AssesmentYearArray, value })
  }
  
  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setflag(true);
    // readItemFromStorage();

    getAssesmentYear()
    
    addSelectedCaseDetailsRedux(JSON.parse(localStorage.getItem('selected_case_for_edit')));
    console.log("selected_case_for_edit in useEffect GD",JSON.parse(localStorage.getItem('selected_case_for_edit')))
    // setsseinfoForCondi(JSON.parse(localStorage.getItem('selected_case_for_edit')));
    addSelfAssRedux(JSON.stringify(editItemIs.self_assessment));
    console.log("selectedCaseDetailsFromRedux , =-=-=-=-=-=******=-=-=",selectedCaseDetailsFromRedux);

    // to set for self from redux
    if(selectedCaseDetailsFromRedux !== undefined){
      setShowProcedure(true);
      console.log("in selectedCaseDetailsFromRedux",selectedCaseDetailsFromRedux)
      // setforSelf(JSON.stringify(selectedCaseDetailsFromRedux.self_assessment));
      // setforSelf(selectedCaseDetailsFromRedux.self_assessment);
      console.log("setforSelf",forSelf);
      setserviceIdFinalIs(selectedCaseDetailsFromRedux.service_id);
      setselectedServiceIdIs(selectedCaseDetailsFromRedux.service_id);
      setselectedServiceNameIs(selectedCaseDetailsFromRedux.service);
      setselectedAssesmentYear(selectedCaseDetailsFromRedux.assessment_year)
    }

    if(selfAssesFromRedux !== undefined ){
      setforSelf(selfAssesFromRedux)
    }

    if(superCategoryFromRedux !== undefined){
      setsuperCategoryValue(superCategoryFromRedux)
    }
    if(subCategoryFromRedux !== undefined){
      setsubCategoryValue(subCategoryFromRedux)
    }
    if(subCategoryFetchedFromRedux !== undefined){
      setSubCtegoryFetched(subCategoryFetchedFromRedux)
    }
    if(selectedCategoryFlagFromRedux !== undefined){
      setSelectedCategoryIsCategory(selectedCategoryFlagFromRedux)
    }
    if(lastCategoryFromRedux !== undefined){
      setlastCategoryValue(lastCategoryFromRedux)
    }
    if(sseInfoFromRedux !== undefined){
      setSSEInfo(sseInfoFromRedux)
      console.log("sseInfoFromRedux if condi", )
    }
    // if(yearAssFromRedux !== undefined){
    //   setselectedAssesmentYear(yearAssFromRedux)
    // }
    // if(yearAssFromRedux !== undefined){
    //   setselectedAssesmentYear(yearAssFromRedux)
    // }


    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let category_type = "supercategory"
    console.log("business_id",business_id);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        console.log("super categoryDetails for category list",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true ))
        }
        else
        {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      // to fetch case details of selected case
      const requestOptionsCaseDetails = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': editItemIs.case_id,
      'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseDetails) => {
        setShowProcedure(false);
        console.log("caseDetailson general info page",caseDetails);
        if(caseDetails.success == true)
        {
          
          console.log("caseDetailson general info page",caseDetails.data.case_details);
          let case_details = caseDetails.data.case_details ;
          setlastCategoryValue(case_details.service_id)
          
          if(case_details.self_assessment == false){
            await addSseInfoRedux({
            created_by: "client",
            case_id: case_details.case_id,
            service_id: case_details.service_id,
            assesse_name: case_details.for_assesse_name,
            assesse_id:  case_details.for_assesse_id,
            assesse_email: case_details.for_assesse_email,
            assesse_pan: case_details.for_assesse_pan,
            assesse_phone: case_details.for_assesse_phone,
            assesse_country_code: case_details.for_assesse_country_code,
            self_assessment: case_details.self_assessment,
            assessment_year: case_details.assessment_year,
            device_token: JSON.parse(localStorage.getItem('device_token')),
            session_id: JSON.parse(localStorage.getItem('session_id')),
          })
          await setSSEInfo({
            created_by: "client",
            case_id: case_details.case_id,
            service_id: case_details.service_id,
            assesse_name: case_details.for_assesse_name,
            assesse_id:  case_details.for_assesse_id,
            assesse_email: case_details.for_assesse_email,
            assesse_pan: case_details.for_assesse_pan,
            assesse_phone: case_details.for_assesse_phone,
            assesse_country_code: case_details.for_assesse_country_code,
            self_assessment: case_details.self_assessment,
            assessment_year: case_details.assessment_year,
            device_token: JSON.parse(localStorage.getItem('device_token')),
            session_id: JSON.parse(localStorage.getItem('session_id')),
          });


          let SSEInfoHere = ({
            created_by: "client",
            case_id: case_details.case_id,
            service_id: case_details.service_id,
            assesse_name: case_details.for_assesse_name,
            assesse_id:  case_details.for_assesse_id,
            assesse_email: case_details.for_assesse_email,
            assesse_pan: case_details.for_assesse_pan,
            assesse_phone: case_details.for_assesse_phone,
            assesse_country_code: case_details.for_assesse_country_code,
            self_assessment: case_details.self_assessment,
            device_token: JSON.parse(localStorage.getItem('device_token')),
            session_id: JSON.parse(localStorage.getItem('session_id')),
          })
          console.log("SSEInfoHere in useeffect",SSEInfoHere);
          onSelectService("1",case_details.service_id,case_details.self_assessment,SSEInfoHere,CountryCode,
          case_details.assessment_year)
          }

          // setsseinfoForCondi(case_details);
          setcaseIdFetched(case_details.case_id);
          onSelectService("1",case_details.service_id,case_details.self_assessment,SSEInfo,CountryCode,
          case_details.assessment_year)

          // to get client contacts
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'client_id': case_details.client_id,
            'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
          };
          fetch( process.env.REACT_APP_GET_CLIENT_CONTACTS , requestOptions)
          .then((Response) => Response.json())
          .then( clientContactDetails => {
            if( clientContactDetails.success == true){
              if(clientContactDetails.data.contacts_info)
              {
                console.log("clientContactDetails for list",clientContactDetails.data.contacts_info.contacts);
                setClientContactDetails(clientContactDetails.data.contacts_info.contacts)
              }
            }
            if( clientContactDetails.success == false){
              // Swal.fire({
              //   // icon: 'error',
              //   text: clientContactDetails.errors,
              //   confirmButtonColor: 'primary',
              //   confirmButtonText: 'OK'
              // })
              setClientContactDetails([])
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });

        }
        else if(caseDetails.success == false){
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: caseDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      if(superCategoryFromRedux !== undefined){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'parent_category_id': superCategoryFromRedux,
          'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
          .then((Response) => Response.json())
          .then( subCategoryDetails => {
            console.log("subCategoryDetails for listnin useEffect",subCategoryDetails.data.category_children);
            if(subCategoryDetails.data.category_children)
            {
              setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
            }
            else
            {
              setsubCategoryList([])
            }
            setSubCtegoryFetched(true)
            addSubCategoryFetchedRedux(true)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }

      if(subCategoryFromRedux !== undefined){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'parent_category_id': subCategoryFromRedux,
          'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
          .then((Response) => Response.json())
          .then( categoryLastDetails => {
            console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
            if(categoryLastDetails.data.category_children)
            {
              setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
            }
            else
            {
              setcategoryDetailsLastList([])
            }
            setSubCtegoryFetched(true)
            addSubCategoryFetchedRedux(true)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }


      // if user directly wants to edit without any changes
      // console.log("SSEInfo in useeffect",SSEInfo);
      // onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode)
  }, [flag,editItemIs]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)
  //   } catch (error) {
  //       console.log("ERROR:",error);
  //   }
  // };

  const fillingForursefChange = async(e) => {
    console.log("e of fillingForursefChange",e.target.value)
    let forSelfHere = e.target.value
    await setforSelf(forSelfHere)
    await addSelfAssRedux(forSelfHere)
    await addSseInfoRedux({...SSEInfo,
      self_assessment: JSON.parse(forSelfHere),
      case_id : caseIdFetched,
    })
    // await addSseInfoRedux({...SSEInfo,
    //   self_assessment: JSON.parse(forSelfHere),
    //   case_id : caseIdFetched,
    //   assesse_name: "",
    //   assesse_email: "",
    //   assesse_pan: "",
    //   assesse_phone: "",
    //   self_assessment: JSON.parse(forSelfHere),
    //   service_id: "",
    //   // device_token: JSON.parse(localStorage.getItem('device_token')),
    //   // session_id: JSON.parse(localStorage.getItem('session_id')),
    // })

    await setSSEInfo({...SSEInfo,
      self_assessment: JSON.parse(forSelfHere),
      case_id : caseIdFetched,
    })
    // await  setSSEInfo({
    //   created_by: "client",
    //   case_id : caseIdFetched,
    //   assesse_name: "",
    //   assesse_email: "",
    //   assesse_pan: "",
    //   assesse_phone: "",
    //   self_assessment: JSON.parse(forSelfHere),
    //   service_id: "",
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })
    setsuperCategoryValue("")
    addSuperCategoryRedux("")
    setsubCategoryValue("")
    addSubCategoryRedux("")
    setSubCtegoryFetched(false)
    addSubCategoryFetchedRedux(false)
    setsubCategoryList([])
    setSelectedCategoryIsCategory(false)
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([])
    setlastCategoryValue("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addLastCategoryRedux("")
    setAddNewContactFlag()
    setAssenameOrOtherContactSelectedForSelectFormExistingButton()
    // setselectedAssesmentYear()

    // if(forSelfHere == "true" || forSelfHere == true ){
    //   // await addSseInfoRedux()
    //   // await setSSEInfo()
    //   await addSseInfoRedux({ ...SSEInfo,
    //     assesse_name: "",
    //     assesse_email: "",
    //     assesse_pan: "",
    //     assesse_phone: "",
    //     self_assessment: JSON.parse(forSelfHere),
    //     service_id: "",
    //   })
    //   await setSSEInfo({...SSEInfo,
    //         service_id:  "",
    //         assesse_name: "",
    //         assesse_email: "",
    //         assesse_pan: "",
    //         assesse_phone: "",
    //         self_assessment: JSON.parse(forSelfHere),
    //       });
    //   console.log("in forSelfHere == true || forSelfHere == true check sse sseInfoFromRedux, SSEInfo",sseInfoFromRedux,SSEInfo)
    // }
    // if(forSelfHere == false || forSelfHere == "false" ){

    //   // await setchangeAsseAddFlag(true)

    //   await addSseInfoRedux({...SSEInfo, self_assessment: JSON.parse(forSelfHere) })
    //   await setSSEInfo({...SSEInfo, self_assessment: JSON.parse(forSelfHere) })
    // }
    // if( forSelfHere == "false"){
    //   await addSseInfoRedux({ ...SSEInfo,
    //     assesse_name: "",
    //     assesse_email: "",
    //     assesse_pan: "",
    //     assesse_phone: "",
    //     self_assessment: JSON.parse(forSelfHere),
    //     service_id: "",
    //   })
    // }
    onSelectService("1",serviceIdFinalIs,forSelfHere,SSEInfo,CountryCode,selectedAssesmentYear)
  }

  const handleChangeSuperAcategory = (e) => {
    console.log("e of super category",e.target.value)
    addSuperCategoryRedux(Number(e.target.value))
    setsuperCategoryValue(Number(e.target.value))
    setsubCategoryValue("")
    addSubCategoryRedux("")
    setSelectedCategoryIsCategory(false)
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([])
    setlastCategoryValue("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addLastCategoryRedux("")

    let device_token = JSON.parse(localStorage.getItem('device_token'))
    let session_id = JSON.parse(localStorage.getItem('session_id'))

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
        addSubCategoryFetchedRedux(true);
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e of handleChangeSubcategory",e.target.value)
    // console.log("e of catType",catType)
    // await setsubCategoryValue(Number(e.target.value))
    // await addSubCategoryRedux(Number(e.target.value))
    await setsubCategoryValue(Number(serviceIdFinalIs))
    await addSubCategoryRedux(Number(serviceIdFinalIs))

    // 4 may
    // to set last fropdown values to null
    await addLastCategoryRedux("")
    await setlastCategoryValue("")
    onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // 4 may

    if( serviceIdFinalIs == "" ){
      setSelectedCategoryIsCategory(false)
      onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    }

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(serviceIdFinalIs == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)

    setlastCategoryValue("")
    addLastCategoryRedux("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()

    // if( catType == "service" ){
    if(matchedCategory.category_type == "service"){
      setSelectedCategoryIsCategory(false)
      addSelectedCategoryFlagRedux(false)
      console.log("service is selected now navigate to question set")
      // onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode)
      onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      console.log('serviceID is subCategoryValue', serviceIdFinalIs)
    }
    // else if( catType == "category" ){
    else if( matchedCategory.category_type == "category" ){
      setSelectedCategoryIsCategory(true);
      addSelectedCategoryFlagRedux(true);
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': serviceIdFinalIs,
        'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
        .then((Response) => Response.json())
        .then( categoryLastDetails => {
          console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
          if(categoryLastDetails.data.category_children)
          {
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
          }
          else
          {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true)
          addSubCategoryFetchedRedux(true)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
  }

  const handleChangeLastCategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category",e.target.value)
    // await addLastCategoryRedux(Number(e.target.value))
    // await setlastCategoryValue(Number(e.target.value))
    await addLastCategoryRedux(Number(serviceIdFinalIs))
    await setlastCategoryValue(Number(serviceIdFinalIs))
    await addqsnListRedux()
    await addqsnListLevelTwoRedux()
    await addqsnListLevelThreeRedux()

    console.log("last category service is selected now navigate to question set")
    // onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode);
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    console.log('serviceID is lastCategoryValue', serviceIdFinalIs)
  }

  // const HandleChangeAssesmentYear = async(date) => {
    const HandleChangeAssesmentYear = async(e) => {

    // console.log("in handleChangeAssesment Year", date)
    // console.log("date.getFullYear()",JSON.stringify(date.getFullYear()))
    // let assesmentYearHere = JSON.stringify(date.getFullYear())
    // await setselectedAssesmentYear(assesmentYearHere);
    // await addYearAssRedux(assesmentYearHere);
    // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,assesmentYearHere);


    // dropdown from this
    console.log("e HandleChangeAssesmentYear",e.target.value, lastCategoryValue, lastCategoryFromRedux)
    let assesmentYearIs = e.target.value
    await setselectedAssesmentYear(assesmentYearIs);
    await addYearAssRedux(assesmentYearIs);
    // await addSelectedCaseDetailsRedux({..., assessment_year:assesmentYearIs })
    // lastCategoryFromRedux
    
    onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,assesmentYearIs);

    // onSelectService("1",lastCategoryFromRedux,forSelf,SSEInfo,CountryCode,assesmentYearIs);
    // dropdown to this
  }

  const handleChangeSelectOtherContact = async(e) => {
    console.log("handle ChangeSelectOtherContact for client",e.target.value)
    console.log("serviceIdFinalIs after selecting other contact",serviceIdFinalIs)
    console.log("serviceIdFinalIs after selecting other contact", superCategoryValue)
    
    let valueHere = e.target.value
    if( valueHere == "Add New Contact"){
      console.log("in handle ChangeSelectOtherContact in == Add New Contact",valueHere)
      await setAddNewContactFlag(true);
      // await setAssenameOrOtherContactSelected(true);
      // await setSSEInfo({...SSEInfo, assesse_name: "" })
      // await addSseInfoRedux({...SSEInfo, assesse_name: "" })
      await setSSEInfo({...SSEInfo, assesse_name:"",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
        assesse_id: "",
      })
      await addSseInfoRedux({...SSEInfo, aassesse_name:"",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      assesse_id: "",
      })
    }
    else if(valueHere == ""){
      console.log("in handle ChangeSelectOtherContact in == blank ",valueHere)
      await setAddNewContactFlag(false);
      // await setAssenameOrOtherContactSelected(false);
      await setSSEInfo({...SSEInfo, assesse_name:"" })
      await addSseInfoRedux({...SSEInfo, assesse_name:"" })
    }
    else if(valueHere !== ""){
      console.log("in handle ChangeSelectOtherContact in !== blank ",valueHere)
      await setAddNewContactFlag(false);
      // await setAssenameOrOtherContactSelected(true);
      let user_details = JSON.parse(localStorage.getItem('users'));
      // console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id)
      // let client_id = user_details.data.user_details.business_link.client_id
      ClientContactDetails && ClientContactDetails.map( async(item) =>{
        console.log("ClientContactDetails map in drop",item)
        if( item.assesse_id == valueHere){
          console.log("item.assesse_id == valueHere",item)
          await setSSEInfo({...SSEInfo, assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
            // client_id: client_id,
          })
          await addSseInfoRedux({...SSEInfo, assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
            // client_id: client_id,
          })
        }
      })
    }
    onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
  }

  const handleChangeSelectOtherContactForAddExsistingButton = async(e) => {
    console.log("handle ChangeSelectOtherContact for client",e.target.value)

    let valueHere = e.target.value
    if(valueHere == ""){
      console.log("in handle ChangeSelectOtherContactForAddExsistingButton in == blank ",valueHere)
      await setAssenameOrOtherContactSelectedForSelectFormExistingButton(false);
      await setSSEInfo({...SSEInfo, assesse_name:"" })
      await addSseInfoRedux({...SSEInfo, assesse_name:"" })
    }
    else if(valueHere !== ""){
      console.log("in handle ChangeSelectOtherContactForAddExsistingButton in !== blank ",valueHere)
      await setAssenameOrOtherContactSelectedForSelectFormExistingButton(true);

      ClientContactDetails && ClientContactDetails.map( async(item) =>{
        console.log("ClientContactDetails map in drop",item)
        if( item.assesse_id == valueHere){
          console.log("item.assesse_id == valueHere",item)
          await setSSEInfo({...SSEInfo,
            assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
          })
          await addSseInfoRedux({...SSEInfo,
            assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
          })
        }
      })
    }
    onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
  }

  const handleChangeSSEFormInfo = async(e) => {
    console.log("handle Change SSE Form Info e.target",e);
    // console.log("handle Change SSE Form Info e.target",e.target.value, e.target.name);
    const { name, value } = e.target;
    if ( e.target.name == "assesse_pan" ){
      await addSseInfoRedux({...SSEInfo, [name]:value.toUpperCase() })
      await setSSEInfo({...SSEInfo, [name]:value.toUpperCase() })
    }
    else{
      await addSseInfoRedux({...SSEInfo, [name]:value })
      await setSSEInfo({...SSEInfo, [name]:value })
    }
    // setflag(true);
    console.log("SSEInfo after entering value",SSEInfo);
    // handleSelectServiceFunc();
    // if( forSelf == false ){
    // if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
      // console.log("in if condition of checking all values")
      onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // }
    // }
  }

  // const handleChangeAssesmentYearForSelfFalse = async(date) => {
    const handleChangeAssesmentYearForSelfFalse = async(e) => {

    // console.log("in handleChangeAssesment Year", date)
    // console.log("date.getFullYear()",JSON.stringify(date.getFullYear()))
    // let assesmentYearHere = JSON.stringify(date.getFullYear())
    // await addSseInfoRedux({...SSEInfo, assessment_year: assesmentYearHere })
    // await setSSEInfo({...SSEInfo, assessment_year: assesmentYearHere })
    // console.log("in handleChange AssesmentYearForSelfFalse",SSEInfo)
    // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)

    // for dropdown this to
    let assesmentYearIs = e.target.value ;
    console.log("handleChange AssesmentYearForSelfFalse",e.target.value, assesmentYearIs)

    await addSseInfoRedux({...SSEInfo, assessment_year: assesmentYearIs })
    await setSSEInfo({...SSEInfo, assessment_year: assesmentYearIs })

    console.log("in handleChange AssesmentYearForSelfFalse",SSEInfo)
    onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // for dropdown this 
  }

  // const handleSelectServiceFunc = () => {
  //   console.log("in handleSelectServiceFunc 0000")
  //   onSelectService("1","",forSelf,SSEInfo,CountryCode)
  // }

  const assessephoneNumberChange = async(assessePhoneNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange e",e)
      console.log("phoneNumberChange value",value)
      let countryCodeIs = "+" + e.dialCode
      let splitNoIs = ""
      if(e.countryCode == "in"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "us"){
        // splitNoIs = value.split(" ")[1];
        // splitNoIs = value.substr(value.indexOf(' ')+1);

        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "gb"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ca"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ae"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "sg"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "np"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "jp"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "de"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "bt"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "au"){
        splitNoIs = value.split(" ")[1];
      }

      console.log("splitNoIs",splitNoIs)
      let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
      console.log("noHereIs",noHereIs)
      setCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    // await addSseInfoRedux({...SSEInfo, assesse_phone:noHereIs })
    // await setSSEInfo({...SSEInfo, assesse_phone:noHereIs })
    // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
    await addSseInfoRedux({...SSEInfo, assesse_phone: splitNoIs, assesse_country_code: countryCodeIs  })
    await setSSEInfo({...SSEInfo, assesse_phone: splitNoIs, assesse_country_code: countryCodeIs  })
    onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
  }

  const handleChangeSuperAcategoryForSelfFalse = (e) => {
    console.log("e of super category",e.target.value)
    addSuperCategoryRedux(Number(e.target.value))
    setsuperCategoryValue(Number(e.target.value))
    setsubCategoryValue("")
    addSubCategoryRedux("")
    setSelectedCategoryIsCategory(false)
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([])
    setlastCategoryValue("")
    addLastCategoryRedux("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()

    let device_token = JSON.parse(localStorage.getItem('device_token'))
    let session_id = JSON.parse(localStorage.getItem('session_id'))

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true);
        addSubCategoryFetchedRedux(true);
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategoryForSelfFalse = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e of handleChangeSubcategoryForSelfFalse",e.target.value)
    // console.log("e of catType",catType)
    let service_is = Number(e.target.value)
    console.log("e of service_is",service_is)
    // await addSubCategoryRedux(Number(e.target.value))
    // await setsubCategoryValue(Number(e.target.value))
    await addSubCategoryRedux(Number(serviceIdFinalIs))
    await setsubCategoryValue(Number(serviceIdFinalIs))
    await setlastCategoryValue("")
    await addLastCategoryRedux("")
    await addqsnListRedux()
    await addqsnListLevelTwoRedux()
    await addqsnListLevelThreeRedux()

    // 4 may
    // if( serviceIdFinalIs == ""){
      await addSseInfoRedux({...SSEInfo, service_id: "" })
      await setSSEInfo({...SSEInfo, service_id: "" })
    // }

    await onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(serviceIdFinalIs == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)

    // 4 may
    // SelectedCategoryIsCategory
    if ( serviceIdFinalIs == "" )
    {
      setSelectedCategoryIsCategory(false)
      addSelectedCategoryFlagRedux(false)
      setcategoryDetailsLastList([])
    }

    // if( catType == "service" ){
    if(matchedCategory.category_type == "service"){
      setSelectedCategoryIsCategory(false)
      addSelectedCategoryFlagRedux(false)
      console.log("service is selected now navigate to question set")
      await addSseInfoRedux({...SSEInfo, service_id: JSON.parse(serviceIdFinalIs) })
      await setSSEInfo({...SSEInfo, service_id: serviceIdFinalIs })
      if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
        console.log("in if condition of checking all values",SSEInfo)
        await onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      }
      console.log('serviceID is subCategoryValue', serviceIdFinalIs,SSEInfo)
    }
    // else if( catType == "category" ){
    else if( matchedCategory.category_type == "category" ){
      setSelectedCategoryIsCategory(true)
      addSelectedCategoryFlagRedux(true)
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': serviceIdFinalIs,
        'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
        .then((Response) => Response.json())
        .then( categoryLastDetails => {
          console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
          if(categoryLastDetails.data.category_children)
          {
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
          }
          else
          {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true);
          addSubCategoryFetchedRedux(true);
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
  }

  const handleChangeLastCategoryForSelfFalse = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category",e.target.value)
    let service_is = Number(e.target.value)
    console.log("e of service_is",service_is)
    // await addLastCategoryRedux(Number(e.target.value))
    // await setlastCategoryValue(Number(e.target.value))

    // 13 may
    if ( serviceIdFinalIs == "" ){
      await addLastCategoryRedux("")
      await setlastCategoryValue("")
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
      await addSseInfoRedux({...SSEInfo, service_id: "" })
      await setSSEInfo({...SSEInfo, service_id: "" })
      console.log("last category service is selected now navigate to question set",SSEInfo)
      if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
        console.log("in if condition of checking all values",SSEInfo)
        onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      }
      console.log('serviceID is lastCategoryValue', serviceIdFinalIs);
    }
    else{
      await addLastCategoryRedux(Number(serviceIdFinalIs))
      await setlastCategoryValue(Number(serviceIdFinalIs))
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
      await addSseInfoRedux({...SSEInfo, service_id: JSON.parse(serviceIdFinalIs) })
      await setSSEInfo({...SSEInfo, service_id: JSON.parse(serviceIdFinalIs) })
      console.log("last category service is selected now navigate to question set",SSEInfo)
      if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
        console.log("in if condition of checking all values",SSEInfo)
        onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      }
      console.log('serviceID is lastCategoryValue', serviceIdFinalIs);
    }


    
  }

  const changeSeviceFlagFunc = async() => {
    await setchangeSeviceFlag(true);
    // onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // 4 may
    onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    setsubCategoryList([]);
    setcategoryDetailsLastList([]);
    setsuperCategoryValue("");
    addSuperCategoryRedux("");
    setlastCategoryValue("")
    addLastCategoryRedux("")
    setSubCtegoryFetched(false);
    setSelectedCategoryIsCategory(false);
    // 4 may

    // 4 may for self ass false
    await addSseInfoRedux({...SSEInfo, service_id: "" })
    await setSSEInfo({...SSEInfo, service_id: "" })
    onSelectService("1","",forSelf,SSEInfo,CountryCode,selectedAssesmentYear)

  }

  const changeCloseFunc = async() => {
    await setchangeSeviceFlag(false);
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    setflag(false);
  }

  const asseEditFunc = async() => {
    await setchangeAsseEditFlag(true);

    // 5 april
    await addSseInfoRedux({...SSEInfo,
      service_id: "",
      assesse_name: "",
      assesse_id: "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      assessment_year: "",
      case_id : caseIdFetched,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    await setSSEInfo({ ...SSEInfo,
      service_id: "",
      assesse_name: "",
      assesse_id:  "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      assessment_year: "",
      case_id : caseIdFetched,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setsuperCategoryValue("")
    addSuperCategoryRedux("")
    setsubCategoryValue("")
    addSubCategoryRedux("")
    setSubCtegoryFetched(false);
    addSubCategoryFetchedRedux(false)
    setsubCategoryList([]);
    setSelectedCategoryIsCategory(false)
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addLastCategoryRedux("")
    let SSEInfoHere = ({
      created_by: "client",
      case_id: caseIdFetched,
      service_id: serviceIdFinalIs,
      assesse_name: "",
      assesse_id:  "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      self_assessment: forSelf,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("SSEInfoHere in useeffect asseEditFunc ",SSEInfoHere);
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfoHere,CountryCode,selectedAssesmentYear)

    // onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
  }

  const asseCloseEditFunc = async() => {
    await setchangeAsseEditFlag(false);
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    setflag(false);
  }

  const asseAddFunc = async() => {
    await setchangeAsseAddFlag(true);
    await addSseInfoRedux({...SSEInfo,
      service_id: "",
      assesse_name: "",
      assesse_id: "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      assessment_year: "",
      case_id : caseIdFetched,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    await setSSEInfo({ ...SSEInfo,
      service_id: "",
      assesse_name: "",
      assesse_id:  "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      assessment_year: "",
      case_id : caseIdFetched,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setsuperCategoryValue("")
    addSuperCategoryRedux("")
    setsubCategoryValue("")
    addSubCategoryRedux("")
    setSubCtegoryFetched(false);
    addSubCategoryFetchedRedux(false)
    setsubCategoryList([]);
    setSelectedCategoryIsCategory(false)
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addLastCategoryRedux("")
    let SSEInfoHere = ({
      created_by: "client",
      case_id: caseIdFetched,
      service_id: serviceIdFinalIs,
      assesse_name: "",
      assesse_id:  "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      self_assessment: forSelf,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("SSEInfoHere in useeffect asseAddFunc ",SSEInfoHere);
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfoHere,CountryCode,selectedAssesmentYear)
  }

  const asseCloseAddFunc = async() => {
    await setchangeAsseAddFlag(false);
    setflag(false);
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  return (
    console.log("editItemIs in general details, forSelf,serviceIdFinalIs",
      editItemIs, forSelf,serviceIdFinalIs),
    // console.log("SSEINFO in return, sseNameErr",SSEInfo,sseNameErr),
    // console.log("sseinfoForCondi in return",sseinfoForCondi),
    // console.log("sseinfoForCondi for_assesse_name in return",sseinfoForCondi.for_assesse_name),
    // console.log(" in general details, forSelf,selfAssesFromRedux",
    //   forSelf,selfAssesFromRedux),
    // console.log("selectedCaseDetailsFromRedux.assessment_year, yearAssFromRedux",
    //   selectedCaseDetailsFromRedux,yearAssFromRedux),
    // console.log(" selectedAssesmentYear",selectedAssesmentYear),
    // console.log("ClientContactDetails in ruturn==ß",ClientContactDetails),
    // console.log("lastCategoryValue in return=====",lastCategoryValue),
    <div className={classes.root}>
      {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
       <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </Modal>: null}
      <div className={classes.wrapper}>
        <Grid container item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.subHeadingLabel}>
              {/* Are you filling for Yourself?? */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              
            </Grid>
            {/* <RadioGroup
              row
              aria-label="position"
              name="position"
              value={selfAssesFromRedux}
              // defaultValue={forSelf && forSelf}
              // defaultValue={selfAssesFromRedux && selfAssesFromRedux}
              defaultValue={editItemIs && JSON.stringify(editItemIs.self_assessment)}
              // defaultValue={"true"}
              // onChange={(event, value) => setforSelf(value) }
              onChange={fillingForursefChange}
            >
              <div className={classes.customRadioButton}>
              <FormControlLabel
                // value={JSON.parse("true")}
                value="true"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Yes"
              />
              </div>
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  // value={JSON.parse("false")}
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup> */}
          </Grid>
          
          {
            ( forSelf == "true" || forSelf == true )?
            (
                <Grid container item xs={12} style={{marginTop:'-5%'}}>
                {
                  ( changeSeviceFlag == false)?(
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subHeadingLabel}>
                          Service:
                        </Grid>
                        {/* <Grid container item xs={12} className={classes.dataLabel}>
                            {selectedServiceNameIs && selectedServiceNameIs}
                            <EditIcon onClick={changeSeviceFlagFunc}/>
                        </Grid> */}
                        <Grid container item xs={12} >
                          <Grid  className={classes.dataLabel}>
                            {selectedServiceNameIs && selectedServiceNameIs}
                          </Grid>
                          <Grid style={{cursor:'pointer', marginLeft:'15%'}}>
                            <EditIcon onClick={changeSeviceFlagFunc}/>
                          </Grid>
                        </Grid>
                    </Grid>
                  ):
                  (
                    <Grid container item xs={12} style={{width:'100%'}}>

                      <Grid  item xs={4}  style={{marginTop:'2%'}}>
                        <Grid className={classes.subHeadingLabelSelect}>
                          Super Category *
                        </Grid>
                        {/* <Grid item xs={1} className={classes.subHeadingLabel}>
                          <CloseIcon onClick={changeCloseFunc}/>
                        </Grid> */}
                       <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select
                            native
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={superCategoryValue}
                            defaultValue={superCategoryFromRedux}
                            onChange = {handleChangeSuperAcategory}
                          >
                            <option value="" style={{color:'grey'}}>Select Category</option>
                            {superCategoryList && superCategoryList.map(item =>
                              <option value={item.category_id}>{item.name}</option>
                            )}
                          </Select>
                        </FormControl>
                       
                        <div className={classes.validation} >
                          {
                            ( serviceIdErr )?
                            (
                              <Grid>
                              {
                                (superCategoryFromRedux) ? (<div></div>):(
                                   <div>Please select super category </div>
                                )
                              }
                              </Grid>
                            ):
                            (
                              <Grid></Grid>
                            )
                          }
                        </div>
                        </Grid>
                      </Grid>

                      {/* second sub category options */}
                      {
                        ( SubCtegoryFetched == true )?
                        (
                          <Grid item xs={4} style={{marginTop:'2%'}}>
                            <Grid  className={classes.subHeadingLabelSelect}>
                              Category or Service *
                            </Grid>
                            <Grid>
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                  native
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={subCategoryValue}
                                  defaultValue={subCategoryFromRedux}
                                  onChange={(e) => handleChangeSubcategory(e)}
                                >
                                  <option value="" style={{color:'grey'}}>Select Category or service</option>
                                    {subCategoryList && subCategoryList.map(item =>
                                      <option value={item.category_id}>{item.name}</option>
                                  )}
                                </Select>
                              </FormControl>
                              <div className={classes.validation} style={{marginLeft:'-27%'}}>
                              {
                                ( serviceIdErr )?
                                (
                                  <Grid>
                                  {
                                    (subCategoryValue) ? (<div></div>):(
                                      <div>Please select category or service </div>
                                    )
                                  }
                                  </Grid>
                                ):
                                (
                                  <Grid></Grid>
                                )
                              }
                            </div>
                            </Grid>
                          </Grid>
                        ):
                        (<div></div>)
                      }

                      {/* Third if service navigate else if category then show options */}
                      {
                        ( SelectedCategoryIsCategory == true)?
                        (
                          <Grid item xs={3} style={{marginTop:'2%'}}>
                            <Grid className={classes.subHeadingLabelSelect}>
                              {/* Case Category */}
                              Service *
                            </Grid>
                            <Grid >
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                  native
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={lastCategoryValue}
                                  defaultValue={lastCategoryFromRedux}
                                  onChange={(e) => handleChangeLastCategory(e)}
                                >
                                  <option value="" style={{color:'grey'}}>Select Service</option>
                                    {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                      <option value={item.category_id}>{item.name}</option>
                                  )}
                                </Select>
                              </FormControl>
                              <div className={classes.validation} style={{marginLeft:'-40%'}}>
                                {(SSEInfo && SSEInfo.service_id)?(<div></div>):(serviceIdErr)}
                              </div>
                            </Grid>
                          </Grid>
                        ):
                        (<div></div>)
                      }
                      <Grid  className={classes.CloseIconStyle}>
                        <CloseIcon onClick={changeCloseFunc}/>
                      </Grid>
                    {/*   <div className={classes.validation}>{serviceIdErr}</div> */}

                    </Grid>
                  )

                }
                <Grid item xs={4} style={{marginTop:'2%'}}>
                  <Grid className={classes.subHeadingLabelSelect}>
                    Assesment Year
                  </Grid>
                  {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                      <DatePicker
                        selected={Date.parse(moment(selectedAssesmentYear, 'YYYY').toISOString())}
                        onChange={(date) => HandleChangeAssesmentYear(date)}
                        placeholderText="Select Year"
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={8}
                        minDate={minDate}
                        maxDate={dueDate}
                        className={classes.datePickerStyle}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                  </Grid> */}
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedAssesmentYear}
                      // defaultValue={selectedAssesmentYear && selectedAssesmentYear}
                      // defaultValue={selectedCaseDetailsFromRedux && selectedCaseDetailsFromRedux.assessment_year}
                      // defaultValue={yearAssFromRedux && yearAssFromRedux}
                      // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                      onChange={(e) => HandleChangeAssesmentYear(e) }
                    >
                      <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                      {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                        <option value={item.value}>{item.item}</option>
                      )}
                      {/* <option value="AY-2014-15" >AY-2014-15</option>
                      <option value="AY-2015-16" >AY-2015-16</option>
                      <option value="AY-2016-17" >AY-2016-17</option>
                      <option value="AY-2017-18" >AY-2017-18</option>
                      <option value="AY-2018-19" >AY-2018-19</option>
                      <option value="AY-2019-20" >AY-2019-20</option>
                      <option value="AY-2020-21" >AY-2020-21</option>
                      <option value="AY-2021-22" >AY-2021-22</option>
                      <option value="AY-2022-23" >AY-2022-23</option>
                      <option value="AY-2023-24" >AY-2023-24</option>
                      <option value="AY-2024-25" >AY-2024-25</option>
                      <option value="N/A" >N/A</option> */}

                    </Select>
                  </FormControl>
                  <div className={classes.validation}>{(selectedAssesmentYear && selectedAssesmentYear)?(<div></div>):(assesmentYearErr)}</div>
                </Grid>
              </Grid>
            ):
            (
              // ( forSelf == "false" || forSelf == false && !sseinfoForCondi.for_assesse_name.trim()  )?
              // First if comes on page and select no(1st its yes)
              ( editItemIs && editItemIs.for_assesse_name == " "  )?
              (
                  // console.log(" sseinfo is blank "),
                  <Grid container>
                      <Grid item xs={12} style={{marginTop:'1%'}}>
                        <FormControl variant="outlined" className={classes.formControlOtherContact}>
                          <Select
                            native
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            //s value={SSEInfo.assesse_name}
                            autoFocus
                            onChange = {handleChangeSelectOtherContact}
                          >
                            <option value="" style={{color:'grey'}}>Select Other Contacts</option>
                            {/* {
                              (role_of_user !== "client" && user_permissions && user_permissions.permissions.add_contacts  === true )?
                              (
                                <option value="Add New Contact" style={{color:'blue'}}>Add New Contact</option>
                              ):
                              (
                                <div></div>
                              )
                            } */}
                            {
                              (role_of_user && role_of_user == "client" )?
                              (
                                <option value="Add New Contact" style={{color:'blue'}}>Add New Contact</option>
                              ):
                              (
                                (role_of_user && role_of_user !== "client")?
                                (
                                  (user_permissions && user_permissions.permissions.add_contacts  === true )?
                                  (
                                    <option value="Add New Contact" style={{color:'blue'}}>Add New Contact</option>
                                  ):
                                  (
                                    <div></div>
                                  )
                                ):
                                (
                                  <div></div>
                                )
                              )
                            }
                            {ClientContactDetails && ClientContactDetails.map(item =>
                              <option value={item.assesse_id}>{item.name}</option>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      {
                        ( AddNewContactFlag == true )?
                        (
                          <Grid container>
                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              {/* ASSESSE Name */}
                              Tax Payer Name
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="assesse_name"
                                // value={SSEInfo.assesse_name}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            {/* <div className={classes.validation}>{(SSEInfo.assesse_name)?(<div></div>):(sseNameErr)}</div> */}
                            <div className={classes.validation}>{sseNameErr}</div>

                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              {/* ASSESSE Email */}
                              Tax Payer Email
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="assesse_email"
                                // value={SSEInfo.assesse_email}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            {/* <div className={classes.validation}>{(SSEInfo.assesse_email)?(<div></div>):(sseEmailErr)}</div> */}
                            <div className={classes.validation}>{sseEmailErr}</div>
                            <div className={classes.validation}>{sseEmailIncorrectErr}</div>

                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              {/* ASSESSE Phone Number */}
                              Tax Payer Phone Number
                            </Grid>
                            <Grid item xs={12}>
                              {/* <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="assesse_phone"
                                name="assesse_phone"
                                // value={SSEInfo.assesse_phone}
                                onChange = {handleChangeSSEFormInfo}
                              /> */}
                              <PhoneInput
                                inputStyle={{
                                  width: "100%",
                                  height: "50px",
                                  fontSize: "15px",
                                  autoFocus:true
                                }}
                                countryCodeEditable={false}
                                onlyCountries={onlyCountryArray}
                                masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                                  sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                                  au: '.............'  }}
                                specialLabel=""
                                country={'in'}
                                // value={SSEInfo.assesse_phone}
                                value={SSEInfo.assesse_country_code + SSEInfo.assesse_phone}
                                onChange={(assessePhoneNumber,e,formattedValue,value) => assessephoneNumberChange(assessePhoneNumber,e,formattedValue,value)}
                              />
                            </Grid>
                            {/* <div className={classes.validation}>{(SSEInfo.assesse_phone)?(<div></div>):(ssePhoneNoErr)}</div> */}
                            <div className={classes.validation}>{ssePhoneNoErr}</div>
                            <div className={classes.validation}>{ssePhoneNoIncorrectErr}</div>
                            <div className={classes.validation}>{ssePhoneNoNotDigitErr}</div>

                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Grid className={classes.subHeadingLabel}>
                                {/* ASSESSE PAN */}
                                Tax Payer PAN
                              </Grid>
                              <Grid >
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  // size="small"
                                  fullWidth
                                  name="assesse_pan"
                                  Placeholder="XXXXX0000X"
                                  inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                                  // value={SSEInfo.assesse_pan}
                                  onChange = {handleChangeSSEFormInfo}
                                />
                              </Grid>
                              {/* <div className={classes.validation}>{(SSEInfo.assesse_pan)?(<div></div>):(ssePanErr)}</div> */}
                              <div className={classes.validation}>{ssePanErr}</div>
                              <div className={classes.validation}>{ssePanNotValidErr}</div>
                            </Grid>

                            <Grid item xs={6}>
                              <Grid style={{marginTop:'5%', marginLeft:'2%'}} className={classes.subHeadingLabel}>
                                Assesment Year
                              </Grid>
                              {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                                <DatePicker
                                  selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                  defaultValue={yearAssFromRedux}
                                  onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                  placeholderText="Select Year"
                                  showYearPicker
                                  dateFormat="yyyy"
                                  yearItemNumber={8}
                                  minDate={minDate}
                                  maxDate={dueDate}
                                  className={classes.datePickerStyle}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </Grid> */}
                              <FormControl variant="outlined" className={classes.formControlAssYear}>
                                <Select
                                  native
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={SSEInfo.assessment_year}
                                  defaultValue={yearAssFromRedux}
                                  // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                  onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                >
                                  <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                  {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                    <option value={item.value}>{item.item}</option>
                                  )}
                                  {/* <option value="AY-2014-15" >AY-2014-15</option>
                                  <option value="AY-2015-16" >AY-2015-16</option>
                                  <option value="AY-2016-17" >AY-2016-17</option>
                                  <option value="AY-2017-18" >AY-2017-18</option>
                                  <option value="AY-2018-19" >AY-2018-19</option>
                                  <option value="AY-2019-20" >AY-2019-20</option>
                                  <option value="AY-2020-21" >AY-2020-21</option>
                                  <option value="AY-2021-22" >AY-2021-22</option>
                                  <option value="AY-2022-23" >AY-2022-23</option>
                                  <option value="AY-2023-24" >AY-2023-24</option>
                                  <option value="AY-2024-25" >AY-2024-25</option>
                                  <option value="N/A" >N/A</option> */}
                                </Select>
                              </FormControl>
                              <div className={classes.validation}>{(SSEInfo && SSEInfo.assessment_year)?(<div></div>):(sseAssesYearErr)}</div>
                            </Grid>
                            </Grid>

                            <Grid container>
                              {
                                <Grid container item xs={12}>
                                  <Grid item xs={3} style={{marginTop:'2%'}}>
                                    <Grid className={classes.subHeadingLabelSelect}>
                                      Case Super Category *
                                    </Grid>
                                    <Grid>
                                      <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                          native
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={superCategoryValue}
                                          defaultValue={superCategoryFromRedux}
                                          onChange = {handleChangeSuperAcategoryForSelfFalse}
                                        >
                                          <option value="" style={{color:'grey'}}>Select Category</option>
                                            {superCategoryList && superCategoryList.map(item =>
                                              <option value={item.category_id}>{item.name}</option>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    {/* <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div> */}
                                    <div className={classes.validation}>{sseServiceErr}</div>
                                  </Grid>
                                  {
                                      ( SubCtegoryFetched == true )?
                                        (
                                          <Grid item xs={3} style={{marginTop:'2%'}}>
                                            <Grid className={classes.subHeadingLabelSelect}>
                                              Case Service or Category
                                            </Grid>
                                          <Grid >
                                              <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                  native
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={subCategoryValue}
                                                  defaultValue={subCategoryFromRedux}
                                                  onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                                >
                                                  <option value="" style={{color:'grey'}}>Select Category</option>
                                                    {subCategoryList && subCategoryList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                  )}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                          </Grid>
                                        ):
                                        (<div></div>)
                                      }
                                      {
                                        ( SelectedCategoryIsCategory == true)?
                                        (
                                          <Grid item xs={3} style={{marginTop:'2%'}}>
                                            <Grid className={classes.subHeadingLabelSelect}>
                                              {/* Case Category */}
                                              Case Service
                                            </Grid>
                                            <Grid >
                                              <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                  native
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={lastCategoryValue}
                                                  defaultValue={lastCategoryFromRedux}
                                                  onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                                >
                                                  <option value="" style={{color:'grey'}}>Select Category</option>
                                                    {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                  )}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                          </Grid>
                                        ):
                                        (<div></div>)
                                      }
                                </Grid>
                              }
                            </Grid>

                          </Grid>
                        ):
                        (
                          <Grid></Grid>
                        )
                      }

                      {
                        ( AddNewContactFlag == false)?
                        (
                          <Grid container>
                              {
                                <Grid container item xs={12}>
                                  <Grid item xs={3} style={{marginTop:'2%'}}>
                                    <Grid className={classes.subHeadingLabelSelect}>
                                      Case Super Category * 
                                    </Grid>
                                    <Grid>
                                      <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                          native
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={superCategoryValue}
                                          defaultValue={superCategoryFromRedux}
                                          onChange = {handleChangeSuperAcategoryForSelfFalse}
                                        >
                                          <option value="" style={{color:'grey'}}>Select Category</option>
                                            {superCategoryList && superCategoryList.map(item =>
                                              <option value={item.category_id}>{item.name}</option>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    {/* <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div> */}
                                    <div className={classes.validation}>{sseServiceErr}</div>
                                  </Grid>
                                  {
                                      ( SubCtegoryFetched == true )?
                                        (
                                          <Grid item xs={3} style={{marginTop:'2%'}}>
                                            <Grid className={classes.subHeadingLabelSelect}>
                                              Case Service or Category
                                            </Grid>
                                          <Grid >
                                              <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                  native
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={subCategoryValue}
                                                  defaultValue={subCategoryFromRedux}
                                                  onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                                >
                                                  <option value="" style={{color:'grey'}}>Select Category</option>
                                                    {subCategoryList && subCategoryList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                  )}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                          </Grid>
                                        ):
                                        (<div></div>)
                                      }
                                      {
                                        ( SelectedCategoryIsCategory == true)?
                                        (
                                          <Grid item xs={3} style={{marginTop:'2%'}}>
                                            <Grid className={classes.subHeadingLabelSelect}>
                                              {/* Case Category */}
                                              Case Service
                                            </Grid>
                                            <Grid >
                                              <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                  native
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={lastCategoryValue}
                                                  defaultValue={lastCategoryFromRedux}
                                                  onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                                >
                                                  <option value="" style={{color:'grey'}}>Select Category</option>
                                                    {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                      <option value={item.category_id}>{item.name}</option>
                                                  )}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                          </Grid>
                                        ):
                                        (<div></div>)
                                      }
                                </Grid>
                              }
                              <Grid item xs={3}>
                              <Grid style={{marginTop:'1%'}} className={classes.subHeadingLabel}>
                                Assesment Year
                              </Grid>
                              {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                                <DatePicker
                                  selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                  defaultValue={yearAssFromRedux}
                                  onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                  placeholderText="Select Year"
                                  showYearPicker
                                  dateFormat="yyyy"
                                  yearItemNumber={8}
                                  minDate={minDate}
                                  maxDate={dueDate}
                                  className={classes.datePickerStyle}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </Grid> */}
                              <FormControl variant="outlined" className={classes.formControlAssYear}>
                                <Select
                                  native
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={SSEInfo.assessment_year}
                                  defaultValue={yearAssFromRedux}
                                  // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                  onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                >
                                  <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                  {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                    <option value={item.value}>{item.item}</option>
                                  )}
                                  {/* <option value="AY-2014-15" >AY-2014-15</option>
                                  <option value="AY-2015-16" >AY-2015-16</option>
                                  <option value="AY-2016-17" >AY-2016-17</option>
                                  <option value="AY-2017-18" >AY-2017-18</option>
                                  <option value="AY-2018-19" >AY-2018-19</option>
                                  <option value="AY-2019-20" >AY-2019-20</option>
                                  <option value="AY-2020-21" >AY-2020-21</option>
                                  <option value="AY-2021-22" >AY-2021-22</option>
                                  <option value="AY-2022-23" >AY-2022-23</option>
                                  <option value="AY-2023-24" >AY-2023-24</option>
                                  <option value="AY-2024-25" >AY-2024-25</option>
                                  <option value="N/A" >N/A</option> */}
                                </Select>
                              </FormControl>
                              <div className={classes.validation}>{(SSEInfo && SSEInfo.assessment_year)?(<div></div>):(sseAssesYearErr)}</div>
                            </Grid>
                            </Grid>
                        )
                        :(<Grid></Grid>)
                      }


                  </Grid>
              ):
              (
                // for self is false from main
                console.log(" sseinfo is not blank ",SSEInfo),
                <Grid container>
                {
                  ( changeAsseEditFlag == false && changeAsseAddFlag == false )?
                  (
                    // {/* card showing ASSESSE Info */}
                    <Grid item xs={12} style={{marginTop:'-7%'}}>
                      <Paper className={classes.paper}>
                          <Grid container >
                              <Grid item xs={4} style={{ color:'black', marginTop:'0.3%', fontWeight:'bold'}}>
                                  {/* ASSESSE Info */}
                                  Tax Payer Details
                              </Grid>
                              {/* <Grid item xs={1} className={classes.moreVertIcon}>
                                  <EditIcon onClick={asseEditFunc}/>
                              </Grid> */}
                              <Grid item xs={4} className={classes.moreVertIcon} style={{marginTop:'-0.5%',marginLeft:'6%'}}>
                                <Button
                                  variant="contained"
                                  // color="primary"
                                  className={classes.submit}
                                  onClick={asseEditFunc}
                                >
                                  Select Another Contact
                                </Button>
                              </Grid>
                              <Grid item xs={3} className={classes.moreVertIcon} style={{marginTop:'-0.5%',marginLeft:"-2%"}}>
                                {/* <Tooltip
                                  title="Add Another Contact"
                                  placement="center"
                                >
                                  <AddIcon onClick={asseAddFunc}/>
                                </Tooltip> */}
                                <Button
                                  variant="contained"
                                  // color="primary"
                                  className={classes.submit}
                                  onClick={asseAddFunc}
                                >
                                  Add Another Contact
                                </Button>
                              </Grid>
                          </Grid>
                          <hr/>
                          <Grid container>
                          <Grid item xs={12}  className={classes.userAddUpdateDelete}>
                                {/* ASSESSE Name : { SSEInfo && SSEInfo.assesse_name}  */}
                                Tax Payer Name : { SSEInfo && SSEInfo.assesse_name}
                              </Grid>
                              <Grid item xs={12} className={classes.userAddUpdateDelete}>
                                {/* ASSESSE Email : { SSEInfo && SSEInfo.assesse_email} */}
                                Tax Payer Email : { SSEInfo && SSEInfo.assesse_email}
                              </Grid>
                              <Grid item xs={12} className={classes.userAddUpdateDelete}>
                                {/* ASSESSE Phone Number : { SSEInfo && SSEInfo.assesse_phone} */}
                                {/* Tax Payer Phone Number : { SSEInfo && SSEInfo.assesse_phone} */}
                                Tax Payer Phone Number : { SSEInfo && SSEInfo.assesse_country_code + "-" + SSEInfo.assesse_phone}
                              </Grid>
                              <Grid item xs={12} className={classes.userAddUpdateDelete}>
                                {/* ASSESSE PAN : { SSEInfo && SSEInfo.assesse_pan} */}
                                Tax Payer PAN : { SSEInfo && SSEInfo.assesse_pan}
                              </Grid>
                              {/* <Grid item xs={12} className={classes.userAddUpdateDelete}> */}
                                {/* ASSESMENT Year : { SSEInfo && SSEInfo.assessment_year} this is already commented */}
                                {/* ASSESMENT Year : { selectedAssesmentYear && selectedAssesmentYear} */}
                              {/* </Grid> */}
                          </Grid>
                      </Paper>

                      <Grid item xs={3}>
                                <Grid style={{marginTop:'2%', marginLeft:'3%'}} className={classes.subHeadingLabel}>
                                  Assesment Year
                                </Grid>
                                <FormControl variant="outlined" className={classes.formControlAssYear}>
                                  <Select
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={SSEInfo.assessment_year}
                                    defaultValue={yearAssFromRedux}
                                    // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                    onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                  >
                                    <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                    {AssesmentYearArray.value && AssesmentYearArray.value.map(item =>
                                      <option value={item.value}>{item.item}</option>
                                    )}
                                   
                                  </Select>
                                </FormControl>
                                <div className={classes.validation} style={{textAlign:'left', marginLeft:'2%'}}>
                                  {(SSEInfo && SSEInfo.assessment_year)?(<div></div>):(sseAssesYearErr)}
                                </div>
                              </Grid>

                      {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                        Service : {selectedServiceNameIs && selectedServiceNameIs}
                      </Grid> */}
                      <Grid container style={{marginBottom:'1%'}}>
                      {
                        ( changeSeviceFlag == false)?(
                          <Grid item xs={12} style={{marginLeft:'2%'}}>
                              <Grid item xs={12} className={classes.subHeadingLabel}>
                                  Service:
                              </Grid>
                              <Grid container item xs={12}>
                                <Grid  item xs={11} className={classes.dataLabel}>
                                  {selectedServiceNameIs && selectedServiceNameIs}
                                </Grid>
                                <Grid item xs={1} className={classes.dataLabel} style={{cursor:'pointer',}}>
                                  <EditIcon onClick={changeSeviceFlagFunc}/>
                                </Grid>
                              </Grid>
                          </Grid>
                        ):
                        (
                          <Grid container item xs={12}>
                            <Grid item xs={4} style={{marginTop:'2%'}}>
                              <Grid className={classes.subHeadingLabelSelect}>
                                Super Category *
                              </Grid>
                              <Grid >
                                <FormControl variant="outlined" className={classes.formControl}>
                                  <Select
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={superCategoryValue}
                                    defaultValue={superCategoryFromRedux}
                                    onChange = {handleChangeSuperAcategoryForSelfFalse}
                                  >
                                    <option value="" style={{color:'grey'}}>Select Super Category</option>
                                      {superCategoryList && superCategoryList.map(item =>
                                        <option value={item.category_id}>{item.name}</option>
                                    )}
                                  </Select>
                                </FormControl>
                                {/* {
                                  superCategoryList.map((item, index) =>{
                                  // console.log("item map is",item);
                                  return(
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="position"
                                      value={superCategoryValue}
                                      defaultValue={superCategoryFromRedux}
                                      // onChange={(event, value) => setsuperCategoryValue(value) }
                                      onChange={handleChangeSuperAcategoryForSelfFalse}
                                    >
                                      <div className={classes.customRadioButton}>
                                      <FormControlLabel
                                        key={index}
                                        value={item.category_id}
                                        classes={{ label: classes.label }}
                                        control={<Radio size="small" color="primary" />}
                                        label={item.name}
                                      />
                                      </div>
                                    </RadioGroup>
                                    )
                                    })
                                  } */}
                                </Grid>
                                {/* <div className={classes.validation}>{(SSEInfo && SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div> */}
                                  <div className={classes.validation} style={{marginLeft:'-35%'}}>
                                    {
                                      ( sseServiceErr )?
                                      (
                                        <Grid>
                                          {
                                            (superCategoryValue) ? (<div></div>):(
                                              <div>Please select super category </div>
                                            )
                                          }
                                        </Grid>
                                      ):
                                      (
                                        <Grid></Grid>
                                      )
                                    }
                                  </div>
                              </Grid>
                              {
                                ( SubCtegoryFetched == true )?
                                  (
                                    <Grid item xs={4} style={{marginTop:'2%'}} >
                                      <Grid className={classes.subHeadingLabelSelect}>
                                        Category or Service *
                                      </Grid>
                                      <Grid >
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={subCategoryValue}
                                            defaultValue={subCategoryFromRedux}
                                            onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                              {subCategoryList && subCategoryList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div className={classes.validation} style={{marginLeft:'-30%'}}>
                                          {
                                            ( sseServiceErr )?
                                            (
                                              <Grid>
                                                {
                                                  (subCategoryValue) ? (<div></div>):(
                                                    <div>Please select category or service</div>
                                                  )
                                                }
                                              </Grid>
                                            ):
                                            (
                                              <Grid></Grid>
                                            )
                                          }
                                        </div>
                                      {/* {
                                        subCategoryList.map((item, index) =>{
                                        // console.log("item map is",item);
                                          return(
                                            <RadioGroup
                                              row
                                              aria-label="position"
                                              name="position"
                                              value={subCategoryValue}
                                              defaultValue={subCategoryFromRedux}
                                              onChange={(e) => handleChangeSubcategoryForSelfFalse(e, item.category_type)}
                                            >
                                              <div className={classes.customRadioButton}>
                                                <FormControlLabel
                                                  key={index}
                                                  value={item.category_id}
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label={item.name}
                                                />
                                              </div>
                                            </RadioGroup>
                                          )
                                        })
                                      } */}
                                      </Grid>
                                    </Grid>
                                  ):
                                  (<div></div>)
                                }
                                {
                                  ( SelectedCategoryIsCategory == true)?
                                  (
                                    <Grid item xs={3} style={{marginTop:'2%'}}>
                                      <Grid className={classes.subHeadingLabelSelect}>
                                        {/* Case Category */}
                                        Service *
                                      </Grid>
                                      <Grid >
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={lastCategoryValue}
                                            defaultValue={lastCategoryFromRedux}
                                            onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Service</option>
                                              {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div className={classes.validation} style={{marginLeft:'-45%'}}>
                                          {(SSEInfo && SSEInfo.service_id)?(<div></div>):(sseServiceErr)}
                                        </div>
                                      {/* {
                                        categoryDetailsLastList.map((item, index) =>{
                                        // console.log("item map is",item);
                                          return(
                                            <RadioGroup
                                              row
                                              aria-label="position"
                                              name="position"
                                              value={lastCategoryValue}
                                              defaultValue={lastCategoryFromRedux}
                                              onChange={handleChangeLastCategoryForSelfFalse}
                                            >
                                              <div className={classes.customRadioButton}>
                                                <FormControlLabel
                                                  key={index}
                                                  value={item.category_id}
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label={item.name}
                                                />
                                              </div>
                                            </RadioGroup>
                                          )
                                        })
                                      } */}
                                      </Grid>
                                    </Grid>
                                  ):
                                  (<div></div>)
                                }
                            <Grid item xs={1} className={classes.closeIconStyle}
                              // style={{marginLeft:'1%', marginTop:'-0.1%'}}
                            >
                              <CloseIcon onClick={changeCloseFunc}/>
                            </Grid>
                          </Grid>
                        )
                      }
                      </Grid>


                    </Grid>
                  ):
                  (<div></div>)
                }

                {/* Edit Form of ASSESSE now converted to select from existing*/}
                {
                  ( changeAsseEditFlag == true )?(
                    <Grid container>

                      <Grid item xs={12} className={classes.moreVertIcon}>
                        <CloseIcon onClick={asseCloseEditFunc}/>
                      </Grid>

                      <Grid item xs={12} style={{marginTop:'1%'}}>
                        <FormControl variant="outlined" className={classes.formControlOtherContact}>
                          <Select
                            native
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // value={SSEInfo.assesse_name}
                            autoFocus
                            onChange = {handleChangeSelectOtherContactForAddExsistingButton}
                          >
                            <option value="" style={{color:'grey'}}>Select Other Contact</option>
                            {ClientContactDetails && ClientContactDetails.map(item =>
                              <option value={item.assesse_id}>{item.name}</option>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_name)?(<div></div>):(sseNameErr)}</div>


                      {
                        ( AssenameOrOtherContactSelectedForSelectFormExistingButton == true )?
                        (
                          <Grid item xs={12}>
                            <Grid item xs={12} container>
                              {
                                  <Grid container item xs={12}>
                                    <Grid item xs={3} style={{marginTop:'2%'}}>
                                      <Grid className={classes.subHeadingLabelSelect}>
                                        Super Category *
                                      </Grid>
                                      <Grid >
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={superCategoryValue}
                                            defaultValue={superCategoryFromRedux}
                                            onChange = {handleChangeSuperAcategoryForSelfFalse}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Super Category</option>
                                              {superCategoryList && superCategoryList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      {/* <div className={classes.validation}>{sseServiceErr}</div> */}
                                      <div className={classes.validation}>
                                        {
                                          ( sseServiceErr )?
                                          (
                                            <Grid>
                                              {
                                                (superCategoryValue) ? (<div></div>):(
                                                  <div>Please select super category  </div>
                                                )
                                              }
                                            </Grid>
                                          ):
                                          (
                                            <Grid></Grid>
                                          )
                                        }
                                      </div>
                                    </Grid>
                                    {
                                        ( SubCtegoryFetched == true )?
                                          (
                                            <Grid item xs={3} style={{marginTop:'2%'}} >
                                              <Grid className={classes.subHeadingLabelSelect}>
                                                Category or Service *
                                              </Grid>
                                              <Grid >
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={subCategoryValue}
                                                    defaultValue={subCategoryFromRedux}
                                                    onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                                  >
                                                    <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                                      {subCategoryList && subCategoryList.map(item =>
                                                        <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                <div className={classes.validation} style={{marginLeft:'-10%'}}>
                                                  {
                                                    ( sseServiceErr )?
                                                    (
                                                      <Grid>
                                                        {
                                                          (subCategoryValue) ? (<div></div>):(
                                                            <div>Please select category or service </div>
                                                          )
                                                        }
                                                      </Grid>
                                                    ):
                                                    (
                                                      <Grid></Grid>
                                                    )
                                                  }
                                                </div>
                                              </Grid>
                                            </Grid>
                                          ):
                                          (<div></div>)
                                        }
                                        {
                                          ( SelectedCategoryIsCategory == true)?
                                          (
                                            <Grid item xs={3} style={{marginTop:'2%'}}>
                                              <Grid className={classes.subHeadingLabelSelect}>
                                                {/* Case Category */}
                                                 Service *
                                              </Grid>
                                              <Grid>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                  <Select
                                                    native
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={lastCategoryValue}
                                                    defaultValue={lastCategoryFromRedux}
                                                    onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                                  >
                                                    <option value="" style={{color:'grey'}}>Select Service</option>
                                                      {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                        <option value={item.category_id}>{item.name}</option>
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                {/* <div className={classes.validation}>{sseServiceErr}</div> */}
                                                <div className={classes.validation} style={{marginLeft:'-45%'}}>
                                                  {
                                                    ( sseServiceErr )?
                                                    (
                                                      <Grid>
                                                        {
                                                          (lastCategoryValue) ? (<div></div>):(
                                                            <div>{sseServiceErr} </div>
                                                          )
                                                        }
                                                      </Grid>
                                                    ):
                                                    (
                                                      <Grid></Grid>
                                                    )
                                                  }
                                                </div>
                                              </Grid>
                                            </Grid>
                                          ):
                                          (<div></div>)
                                        }
                                  </Grid>
                              }
                              </Grid>

                              <Grid item xs={6}>
                                <Grid style={{marginTop:'5%', marginLeft:'2%'}} className={classes.subHeadingLabel}>
                                  Assesment Year
                                </Grid>
                                {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                                  <DatePicker
                                    selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                    defaultValue={yearAssFromRedux}
                                    onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                    placeholderText="Select Year"
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={8}
                                    minDate={minDate}
                                    maxDate={dueDate}
                                    className={classes.datePickerStyle}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Grid> */}
                                <FormControl variant="outlined" className={classes.formControlAssYear}>
                                  <Select
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={SSEInfo.assessment_year}
                                    defaultValue={yearAssFromRedux}
                                    // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                    onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                  >
                                    <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                    {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                      <option value={item.value}>{item.item}</option>
                                    )}
                                    {/* <option value="AY-2014-15" >AY-2014-15</option>
                                    <option value="AY-2015-16" >AY-2015-16</option>
                                    <option value="AY-2016-17" >AY-2016-17</option>
                                    <option value="AY-2017-18" >AY-2017-18</option>
                                    <option value="AY-2018-19" >AY-2018-19</option>
                                    <option value="AY-2019-20" >AY-2019-20</option>
                                    <option value="AY-2020-21" >AY-2020-21</option>
                                    <option value="AY-2021-22" >AY-2021-22</option>
                                    <option value="AY-2022-23" >AY-2022-23</option>
                                    <option value="AY-2023-24" >AY-2023-24</option>
                                    <option value="AY-2024-25" >AY-2024-25</option>
                                                  <option value="N/A" >N/A</option> */}
                                  </Select>
                                </FormControl>
                                <div className={classes.validation} style={{textAlign:'left', marginLeft:'2%'}}>
                                  {(SSEInfo && SSEInfo.assessment_year)?(<div></div>):(sseAssesYearErr)}
                                </div>
                              </Grid>

                          </Grid>
                        )
                        :(<Grid></Grid>)
                      }


                      {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                        Tax Payer Name a
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="assesse_name"
                          value={SSEInfo.assesse_name}
                          onChange = {handleChangeSSEFormInfo}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_name)?(<div></div>):(sseNameErr)}</div> */}

                      {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                        Tax Payer Email
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="assesse_email"
                          value={SSEInfo.assesse_email}
                          onChange = {handleChangeSSEFormInfo}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_email)?(<div></div>):(sseEmailErr)}</div>
                      <div className={classes.validation}>{sseEmailIncorrectErr}</div> */}

                      {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                        Tax Payer Phone Number
                      </Grid>
                      <Grid item xs={12}>
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            height: "50px",
                            fontSize: "15px",
                            autoFocus:true
                          }}
                          countryCodeEditable={false}
                          onlyCountries={onlyCountryArray}
                          specialLabel=""
                          country={'in'}
                          masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                            sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                            au: '.............'  }}
                          value={SSEInfo.assesse_phone}
                          onChange={(assessePhoneNumber,e,formattedValue,value) => assessephoneNumberChange(assessePhoneNumber,e,formattedValue,value)}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_phone)?(<div></div>):(ssePhoneNoErr)}</div>
                      <div className={classes.validation}>{ssePhoneNoIncorrectErr}</div>
                      <div className={classes.validation}>{ssePhoneNoNotDigitErr}</div> */}


                      <Grid container item xs={12}>
                        {/* <Grid item xs={6}>
                          <Grid className={classes.subHeadingLabel}>
                            Tax Payer PAN
                          </Grid>
                          <Grid>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              // size="small"
                              fullWidth
                              name="assesse_pan"
                              placeholder="XXXXX0000X"
                              inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                              value={SSEInfo.assesse_pan}
                              onChange = {handleChangeSSEFormInfo}
                            />
                          </Grid>
                          <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_pan)?(<div></div>):(ssePanErr)}</div>
                          <div className={classes.validation}>{ssePanNotValidErr}</div>
                        </Grid> */}

                      </Grid>

                    </Grid>
                  ):
                  (<div></div>)
                }

                {/* Add Form of ASSESSE */}
                {
                  ( changeAsseAddFlag == true )?(
                    <Grid container>
                      <Grid item xs={12} className={classes.moreVertIcon}>
                        <CloseIcon onClick={asseCloseAddFunc}/>
                      </Grid>

                      <Grid item xs={12} className={classes.subHeadingLabel}>
                        {/* ASSESSE Name */}
                        Tax Payer Name
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="assesse_name"
                          // value={SSEInfo.assesse_name}
                          onChange = {handleChangeSSEFormInfo}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_name)?(<div></div>):(sseNameErr)}</div>
                      {/* <div className={classes.validation}>{sseNameErr}</div> */}

                      <Grid item xs={12} className={classes.subHeadingLabel}>
                        {/* ASSESSE Email */}
                        Tax Payer Email
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="assesse_email"
                          // value={SSEInfo.assesse_email}
                          onChange = {handleChangeSSEFormInfo}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_email)?(<div></div>):(sseEmailErr)}</div>
                      <div className={classes.validation}>{sseEmailIncorrectErr}</div>

                      <Grid item xs={12} className={classes.subHeadingLabel}>
                        {/* ASSESSE Phone Number */}
                        Tax Payer Phone Number
                      </Grid>
                      <Grid item xs={12}>
                        {/* <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          id="assesse_phone"
                          name="assesse_phone"
                          // value={SSEInfo.assesse_phone}
                          onChange = {handleChangeSSEFormInfo}
                        /> */}
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            height: "50px",
                            fontSize: "15px",
                            autoFocus:true
                          }}
                          countryCodeEditable={false}
                          onlyCountries={onlyCountryArray}
                          specialLabel=""
                          country={'in'}
                          masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                            sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                            au: '.............'  }}
                          // value={SSEInfo.assesse_phone}
                          value={SSEInfo.assesse_country_code + SSEInfo.assesse_phone}
                          onChange={(assessePhoneNumber,e,formattedValue,value) => assessephoneNumberChange(assessePhoneNumber,e,formattedValue,value)}
                        />
                      </Grid>
                      <div className={classes.validation}>{(SSEInfo && SSEInfo.assesse_phone)?(<div></div>):(ssePhoneNoErr)}</div>
                      <div className={classes.validation}>{ssePhoneNoIncorrectErr}</div>
                      <div className={classes.validation}>{ssePhoneNoNotDigitErr}</div>

                      <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <Grid className={classes.subHeadingLabel}>
                              {/* ASSESSE PAN */}
                              Tax Payer PAN
                            </Grid>
                            <Grid>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="XXXXX0000X"
                                // size="small"
                                fullWidth
                                name="assesse_pan"
                                // value={SSEInfo.assesse_pan}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            <div className={classes.validation} style={{textAlign:'left'}}>{(SSEInfo && SSEInfo.assesse_pan)?(<div></div>):(ssePanErr)}</div>
                            <div className={classes.validation} style={{textAlign:'left'}}>{ssePanNotValidErr}</div>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid style={{marginTop:'4.2%', marginLeft:'2%'}} className={classes.subHeadingLabel}>
                              Assesment Year
                            </Grid>
                                  {/* <Grid style={{ textAlign: "left", marginLeft: '2%' }}>
                                    <DatePicker
                                      selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                      defaultValue={yearAssFromRedux}
                                      onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                      placeholderText="Select Year"
                                      showYearPicker
                                      dateFormat="yyyy"
                                      yearItemNumber={8}
                                      minDate={minDate}
                                      maxDate={dueDate}
                                      className={classes.datePickerStyle}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </Grid> */}
                            <FormControl variant="outlined" className={classes.formControlAssYear}>
                              <Select
                                native
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={SSEInfo.assessment_year}
                                defaultValue={yearAssFromRedux}
                                // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                              >
                                <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                                  <option value={item.value}>{item.item}</option>
                                )}
                                {/* <option value="AY-2014-15" >AY-2014-15</option>
                                <option value="AY-2015-16" >AY-2015-16</option>
                                <option value="AY-2016-17" >AY-2016-17</option>
                                <option value="AY-2017-18" >AY-2017-18</option>
                                <option value="AY-2018-19" >AY-2018-19</option>
                                <option value="AY-2019-20" >AY-2019-20</option>
                                <option value="AY-2020-21" >AY-2020-21</option>
                                <option value="AY-2021-22" >AY-2021-22</option>
                                <option value="AY-2022-23" >AY-2022-23</option>
                                <option value="AY-2023-24" >AY-2023-24</option>
                                <option value="AY-2024-25" >AY-2024-25</option>
                                <option value="N/A" >N/A</option> */}
                              </Select>
                            </FormControl>
                            <div className={classes.validation} style={{textAlign:'left', marginLeft:'2%'}}>{(SSEInfo && SSEInfo.assessment_year)?(<div></div>):(sseAssesYearErr)}</div>
                          </Grid>
                      </Grid>

                      <Grid container>
                      {
                          <Grid container item xs={12}>
                            <Grid item xs={3} style={{marginTop:'2%'}}>
                              <Grid className={classes.subHeadingLabelSelect}>
                                Super Category *
                              </Grid>
                              <Grid >
                                <FormControl variant="outlined" className={classes.formControl}>
                                  <Select
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={superCategoryValue}
                                    defaultValue={superCategoryFromRedux}
                                    onChange = {handleChangeSuperAcategoryForSelfFalse}
                                  >
                                    <option value="" style={{color:'grey'}}>Select Super Category</option>
                                      {superCategoryList && superCategoryList.map(item =>
                                        <option value={item.category_id}>{item.name}</option>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* <div className={classes.validation}>{sseServiceErr}</div> */}
                              <div className={classes.validation} style={{marginLeft:'-35%'}}>
                              {
                                ( sseServiceErr )?
                                (
                                  <Grid>
                                    {
                                      (superCategoryValue) ? (<div></div>):(
                                        <div>Please select super category </div>
                                      )
                                    }
                                  </Grid>
                                ):
                                (
                                  <Grid></Grid>
                                )
                              }
                              </div>
                            </Grid>
                            {
                                ( SubCtegoryFetched == true )?
                                  (
                                    <Grid item xs={3} style={{marginTop:'2%'}} >
                                      <Grid className={classes.subHeadingLabelSelect}>
                                        Category or Service *
                                      </Grid>
                                      <Grid >
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={subCategoryValue}
                                            defaultValue={subCategoryFromRedux}
                                            onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                              {subCategoryList && subCategoryList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div className={classes.validation} style={{marginLeft:'-30%'}}>
                                          {
                                            ( sseServiceErr )?
                                            (
                                              <Grid>
                                                {
                                                  (subCategoryValue) ? (<div></div>):(
                                                    <div>Please select category or service</div>
                                                  )
                                                }
                                              </Grid>
                                            ):
                                            (
                                              <Grid></Grid>
                                            )
                                          }
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ):
                                  (<div></div>)
                                }
                                {
                                  ( SelectedCategoryIsCategory == true)?
                                  (
                                    <Grid item xs={3} style={{marginTop:'2%'}}>
                                      <Grid className={classes.subHeadingLabelSelect}>
                                        {/* Case Category */}
                                        Service *
                                      </Grid>
                                      <Grid>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={lastCategoryValue}
                                            defaultValue={lastCategoryFromRedux}
                                            onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Service</option>
                                              {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                        {/* <div className={classes.validation}>{sseServiceErr}</div> */}
                                        <div className={classes.validation} style={{marginLeft:'-50%'}}>
                                          {
                                            ( sseServiceErr )?
                                            (
                                              <Grid>
                                                {
                                                  (lastCategoryValue) ? (<div></div>):(
                                                    <div>Please select service</div>
                                                  )
                                                }
                                              </Grid>
                                            ):
                                            (
                                              <Grid></Grid>
                                            )
                                          }
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ):
                                  (<div></div>)
                                }
                          </Grid>
                      }
                      </Grid>
                    </Grid>
                  ):
                  (<div></div>)
                }
              </Grid>
              )


            )
        }
        </Grid>
      </div>
    </div>
  );
}

export default injectIntl(EditCaseGeneralDetails);
