// ForgotPasswordForm

import React from 'react';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { authHeader } from '../helpers';
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {Redirect, Link} from "react-router-dom";
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import { PropagateLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { userActions } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    marginLeft: "-20%",
    width: "140%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancel: {
    backgroundColor: '#e0e0e0'
  },
  leftAlign: {
    textAlign: "left",
  },
  leftAlignBackToHome: {
    textAlign: "left",
    marginTop: '1%',
    marginLeft: '1%'
  },
  errorBlock: {
    // marginLeft: "5%",
    // marginRight: "5%",
    width: "100%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));



export default function ForgotPasswordForm() {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [emailid, setemailid] = React.useState("");
  const[emailErr, setemailErr] = React.useState("");
  const[SubmitEmail, setSubmitEmail] = React.useState(false);
  const[redirectToOtp, setredirectToOtp] = React.useState(false);
  const[redirectToLoginPage, setredirectToLoginPage] = React.useState(false);

  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  
    const dispatch = useDispatch()

    React.useEffect(() => {
      // console.log("loggingIn in login",loggingIn)
      console.log("device_token in useeffect login",device_token)
      console.log("session_id in useeffect login",session_id)
      
      let user = JSON.parse(localStorage.getItem('user'));
      console.log("user in useeffect login",user)
  
      if( device_token !== null && session_id !== null && user !== null){
        console.log("device token , session id, user info found in useeffect of login")
        // Swal.fire({
        //   icon: 'error',
        //   text: "You are already logged in in app",
        //   confirmButtonColor: '#d33',
        //   confirmButtonText: 'OK'
        // })
        dispatch(userActions.logout());
      }
      else{
        console.log("device token , session id, user info is empty in useeffect of login")
      }
  
    }, []);
  
  const validate = () => {
    let emailErr = '';

    if(!emailid)  {
        emailErr = 'Email ID is require';
    }
    else if(typeof emailid !== "undefined"){
        let lastAtPos = emailid.lastIndexOf('@');
        let lastDotPos = emailid.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
          emailErr = "Email is not valid";
          if(emailErr) {
            setemailErr(emailErr);
          }
        }
        else {
            setemailErr();    
        }
      }
      else{
        setemailErr();
      }

      if(emailErr){
        setemailErr(emailErr);
        return false;
      }
  
      return true;

  }

  const submitEmailFunc = () => {
    setSubmitEmail(true);
    const isValid = validate();
    if(isValid){
      console.log("submitEmailFunc",emailid);
      localStorage.setItem('emailForChangePassword', JSON.stringify(emailid));
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'email': emailid})
      };
      fetch( process.env.REACT_APP_FORGOT_PASSWORD , requestOptions)
      .then((Response) => Response.json())
      .then(emailDetails => {
        setShowProcedure(false);
        console.log("emailDetails",emailDetails.data.password_reset);
        if(emailDetails.success == true )
        {
          setredirectToOtp(true);
        }
        else
        {
          Swal.fire({
            icon: 'error',
            text: emailDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }
  }
  const handleCancel = () => {
    setredirectToLoginPage(true);
  }

  if(redirectToLoginPage){
    console.log('Cancel button clicked');
    return(
      <Redirect to={'/login'}/>
    )
  }

  if(redirectToOtp == true){
    return(
      <Redirect to={'/EnterOtpForgotPassword'}/>
    )
  }
  else{
  return (
    <div>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.overFlowHidden}
      >
        <Grid item xs className={classes.leftAlignBackToHome}  >
          <Link to='/HomePage' variant="body2" style={{color:'#551A88'}}>
            <FormattedMessage id="backToHomePageLabel" />
           </Link>
        </Grid>
        <div className={classes.paper} style={{marginTop:'30%'}}>
            {/* <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar> */}
          <Typography component="h1" variant="h5">
            Enter Registered Email ID
          </Typography>
          <div>
              <div>
                <div className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="emailid"
                    label="Email ID"
                    name="emailid"
                    autoComplete="emailid"
                    autoFocus
                    value={emailid}
                    onChange={(e) => setemailid(e.target.value) }
                  />            

                    

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={submitEmailFunc}
                  >
                    Login
                  </Button>

                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.cancel}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>

                <Grid item xs={12}>
                  <div>
                    {
                      (SubmitEmail == true && emailErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {emailErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }

                  </div>
                </Grid>
              </div>
          </div> 
        </div>
      </Grid>
    </Grid>
    </div>  
  );
  }
}