import React from "react";
import TaxManagementWrapper from "../TaxManagementWrapper";
import TaxManagementHeader from "../TaxManagementHeader";
import { connect } from "react-redux";
import { userActions } from "../actions";
import { Route, Switch, Redirect } from "react-router-dom";
import { authHeader, store } from '../helpers';
import { browserName , osName , osVersion, fullBrowserVersion, browserVersion, engineName, engineVersion,
getUA, deviceType, BrowserTypes } from 'react-device-detect';
// import store from "../helpers/store"

import { ThemeProvider } from '../ThemeContext'

const localIpUrl = require('local-ip-url');
// console.log('localIpUrl()',localIpUrl(('public')));

class TaxManagementSection extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      drawerOpenCloseSection: '',
    }
  }
  componentDidMount = async() => {
    
    if (localStorage.getItem('firstLoadDone') === null) {
      // If it's the first load, set the flag in local storage to true and reload the page
      localStorage.setItem('firstLoadDone', 1);
      console.log('Check this for refresh : This is the initial load');

      let device_info = { browserName,osName }
      console.log(" device_info in section to pass for create session", device_info )

      let browser_Name = device_info.browserName
      let device_Os = device_info.osName
      console.log("device_info browser_Name, device_Os:", browser_Name, device_Os )
      let device_info_final = browser_Name + ',' + device_Os
      console.log("device_info device_info_final:", device_info_final )
      // console.log("localIpUrl(('public'))", localIpUrl(('public')) )
      this.props.dispatch(userActions.createSession( localIpUrl(('public')), device_info_final ));

    } else {
      console.log('Check this for refresh : This is a page refresh');
      
      this.props.dispatch(userActions.checkSessionValidity());
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      console.log('Check this for refresh :device_token, session_id',device_token,  session_id);
      this.props.dispatch(userActions.getAll( device_token, session_id ));
      
    }

    // this.props.dispatch(userActions.createSession( localIpUrl(('public')), {browserName,osName } ));
    let drawerOpenCloseHere = await JSON.parse(localStorage.getItem('drawerOpenClose'))
    await this.setState({
      drawerOpenCloseSection: drawerOpenCloseHere,
    })

    // console.log("a is",a)
    // const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'device_ip': localIpUrl(('public')), 'device_info':{browserName,osName } })
    // };
    // return fetch( process.env.REACT_APP_CREATE_SESSION , requestOptions)
    // .then((Response) => Response.json())
    // .then( async(sessionInfo) => {
    //     console.log("sessionInfo of create session device_token",sessionInfo.data.session_info.device_token);
    //     console.log("sessionInfo of create session session_id",sessionInfo.data.session_info.session_id);
    //     await localStorage.setItem('device_token', JSON.stringify(sessionInfo.data.session_info.device_token));
    //     await localStorage.setItem('session_id', JSON.stringify(sessionInfo.data.session_info.session_id));
    // })
    // .catch(error => {
    //   console.log(error);
    // });

    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // if (a){
    // let device_token = store.getState().loginDetails;
    // console.log("device_token for getall is",device_token)
    // this.props.dispatch(userActions.getAll(device_token));
    // }
    
  }
  
  render() {
    // console.log("drawerOpenClose in section",JSON.parse(localStorage.getItem('drawerOpenClose')))
    // console.log("drawerOpenCloseSection",this.state.drawerOpenCloseSection)
    const { users } = this.props;
    const drawerOpenClose = JSON.parse(localStorage.getItem('drawerOpenClose'));
    return (
      <div>
        <div>
          <ThemeProvider>
              <header>
                <TaxManagementHeader 
                users={users}
                />
              </header>
              {/* <section>
                {
                  ( drawerOpenClose == true)?
                  (
                    <div style={{marginLeft:'10%'}}>
                      <TaxManagementWrapper users={users}/>
                    </div>
                  ):
                  (
                    <div>
                      <ThemeProvider>
                        <TaxManagementWrapper users={users}/>
                      </ThemeProvider>
                    </div>
                  )
                }
                
              </section> */}
              <section>
                <div>
                  {/* <ThemeProvider> */}
                    <TaxManagementWrapper users={users}/>
                  {/* </ThemeProvider> */}
                </div>
              </section>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(TaxManagementSection);
