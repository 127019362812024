import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch} from 'react-redux';
import { userActions } from "../actions";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    wrapper: {
        marginLeft: "8%",
        marginRight: "7%",
        marginTop: "8%",
        marginBottom: "3%",
    },
    // wrapper: {
    //   margin: "10%",
    //   marginRight: "5%",
    //   marginTop: "10%",
    //   marginBottom: "3%",
    // },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
    casesdisplay: {
      display: "block",
    },
    casesdisplayNone: {
      display: "none",
    },
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
      marginTop:'2%',
    },
    alertConfgLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "20px",
      // color: "blue",
      textAlign: "left",
      textDecoration: "none",
    },
    // linkNoUnderline: {
    //   textDecoration: "none",
    // },
    // root: {
    //   marginTop: '2%',
    //   display: "flex",
    //   // justifyContent: "flex-end",
    //   background: "#ffffff",
    // },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
    },
    backArrow: {
        marginRight: "10%",
    },
    submitButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2%',
    },
    paymentCommentStyle: {
      marginTop: "10%",
      padding: 0,
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.96)",
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  }));

  export default function TrpsPaymentInfo() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [Flag , setFlag] = React.useState(false);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [ShowPage, setShowPage] = React.useState(false);
    const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));
    const [casePaymentDetailsFromApi, setcasePaymentDetailsFromApi] = React.useState();
    const [PaymentAmount, setPaymentAmount] = React.useState("1000");
    const [paymentComment, setpaymentComment] = React.useState("");

    React.useEffect(() => {
        setFlag(true);
        setShowPage(false);
        dispatch(userActions.checkSessionValidity())
        // setShowProcedure(true);

        // to fetch case details of selected case
        const requestOptionsCaseDetails = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
            'device_token': device_token, 'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
          .then((Response) => Response.json())
          .then( async(caseDetails) => {
            setShowProcedure(false);
            setShowPage(true);
            console.log("caseDetailson on show case details",caseDetails);
            if(caseDetails.success == true)
            {
              console.log("caseDetailson on show case details true",caseDetails.data.case_details);
              let case_details = caseDetails.data.case_details ;
              setcasePaymentDetailsFromApi(case_details.case_payment);
            }
            else if(caseDetails.success == false){
              setcasePaymentDetailsFromApi([])
              Swal.fire({
                icon: 'error',
                text: caseDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 9000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }, [Flag]);

    const SendPaymentInfoToClient = () => {
      console.log("SendPaymentInfoToClient",PaymentAmount,paymentComment)
      setShowProcedure(true);

      let rejectCaseObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        case_status: 'payment-pending',
        case_price: PaymentAmount,
        case_alert_message : paymentComment,
        device_token: device_token,
        session_id: session_id,
      })
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };

      fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
          .then((Response) => Response.json())
          .then(casesPaymentPendngUpdated => {
            setShowProcedure(false);
            console.log("casesPaymentPendngUpdated on same page",casesPaymentPendngUpdated);
            if(casesPaymentPendngUpdated.success == true)
            {
              Swal.fire({
                icon: 'success',
                text: "Payemnt details shared with client",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setpaymentComment("")
            }
            if(casesPaymentPendngUpdated.success == false){
              Swal.fire({
                icon: 'error',
                text: casesPaymentPendngUpdated.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }

    return (
      console.log("caseForDetailsFromAsync",caseForDetailsFromAsync),
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
              </modal>: null}
            <div className={classes.wrapper}>
              {
                ( ShowPage == false )?
                (
                  <Grid></Grid>
                ):
                (
                  <div>
                    <Grid container>
                        <Button href="/TaxManagementCaseDetails" className={classes.backButton}>
                        <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                        </Button>
                      <Grid item xs={12} className={classes.pageHeaderLabel}>
                          Payment Details
                      </Grid>
                    </Grid>

                    {
                      ( casePaymentDetailsFromApi && casePaymentDetailsFromApi !== undefined) ?
                      (
                        <Paper className={classes.paper} style={{marginTop:'1%'}}>
                          <Grid style={{ marginTop: '1%' }} className={classes.paymentCommentStyle}>
                            Payment details are already shared with client: <br/>
                            Amount : {casePaymentDetailsFromApi && casePaymentDetailsFromApi.price} <br/>
                          </Grid>
                        </Paper>
                      ):
                      (
                        <Paper className={classes.paper} style={{marginTop:'1%'}}>

                          <Grid style={{ marginTop: '1%' }} className={classes.paymentCommentStyle}>Payment Amount:{PaymentAmount}</Grid>

                          <Grid style={{ marginTop: '2%' }}>
                            <TextField
                              id="comment"
                              name="comment"
                              variant="outlined"
                              autoFocus
                              fullWidth
                              // required
                              label="Comments"
                              value={paymentComment}
                              onChange={(e) => setpaymentComment(e.target.value) }
                            />
                          </Grid>

                          <Grid className={classes.submitButton}>
                            <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  onClick={() => SendPaymentInfoToClient()}
                              >
                                  Send to client
                              </Button> 
                          </Grid>
                        </Paper>
                      )
                    }


                  </div>
                )
              } 
          </div>
    </div>
  );
}