import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { authHeader } from '../../helpers';
import { PropagateLoader } from 'react-spinners';
import { Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
// import useCheckSessionApi from "../../SessionValidity";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import Select from '@material-ui/core/Select';
import { addSelfAssesment, addSuperCategory, addSubCategory, addSubCategoryFetched ,
  addSelectedCategoryFlag, addLastCategory, addsseInfo, addqsnList , addqsnListLevelTwo,
  addqsnListLevelThree, addYearAss, addasseNameOrContactSelected, addnewContactSelected
  } from "../../actions/newcase.actions";
import DatePicker from "react-datepicker";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    background: "#ffffff",
  },
  wrapper: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "5%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  marginRight: {
    marginRight: "4%",
  },
  textTransformNone: {
    textTransform: "none",
  },
  createStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  // customRadioButtonSuperCatergory: {
  //   border: "1px solid rgba(0, 0, 0, 0.32)",
  //   borderRadius: "6px",
  //   paddingLeft: "5px",
  //   paddingRight: "5px",
  //   marginRight: "20px",
  // },
  label: {
    fontSize: "14px",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  subHeadingLabelSelect : {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "2%",
  },
  formControl: {
    marginLeft: "-5%",
    marginTop: '2%',
    width: '90%',
  },
  formControlOtherContact: {
    // marginLeft: "-5%",
    width: '100%',
  },
  datePickerStyle: {
    width: '94%',
    height: '50px',
    borderColor: 'grey',
    borderRadius: '5px',
    borderWidth: '0.5px',
    marginTop: '2%',
  },
}));

function NewCaseGeneralDetailsClientCases({ handleDrawerClose, intl, handleClose, onSelectService,
  sseNameErr, sseEmailErr, ssePhoneNoErr, ssePanErr, sseServiceErr, asseAssessmentYearErr, serviceIdErr, assesmentYearErr}) {
  // redux get
  const selfAssesFromRedux = useSelector(state => state.newCase.selfAssesment);
  const superCategoryFromRedux = useSelector(state => state.newCase.superCategory);
  const subCategoryFromRedux = useSelector(state => state.newCase.subCategory);
  const subCategoryFetchedFromRedux = useSelector(state => state.newCase.subCategoryFetched);
  const selectedCategoryFlagFromRedux = useSelector(state => state.newCase.selectedCategoryFlag);
  const lastCategoryFromRedux = useSelector(state => state.newCase.lastCategory);
  const sseInfoFromRedux = useSelector(state => state.newCase.sseInfo);
  const yearAssFromRedux = useSelector(state => state.newCase.yearAss);
  const asseNameOrContactSelectedFromRedux = useSelector(state => state.newCase.asseNameOrContactSelected);
  const newContactSelectedFromRedux = useSelector(state => state.newCase.newContactSelected);

  const newCaseCreatedCaseIdRedux = useSelector(state => state.newCase.newCaseCreatedCaseId);

  var d1 = new Date();
  var pastYear = d1.getFullYear() - 10;
  d1.setFullYear(pastYear);
  console.log("d==",d1);

  var d2 = new Date();
  var pastYear = d2.getFullYear() + 2;
  d2.setFullYear(pastYear);
  console.log("d==",d2);

  const [dueDate, setdueDate] = React.useState(d2);
  const [minDate, setminDate] = React.useState(d1);

  // redux set
  const dispatch = useDispatch();
  const addSelfAssRedux = (selfAssesment) => dispatch(addSelfAssesment(selfAssesment));
  const addSuperCategoryRedux = (superCategory) => dispatch(addSuperCategory(superCategory));
  const addSubCategoryRedux = (subCategory) => dispatch(addSubCategory(subCategory));
  const addSubCategoryFetchedRedux = (subCategoryFetched) => dispatch(addSubCategoryFetched(subCategoryFetched));
  const addSelectedCategoryFlagRedux = (selectedCategory) => dispatch(addSelectedCategoryFlag(selectedCategory));
  const addLastCategoryRedux = (lastCategory) => dispatch(addLastCategory(lastCategory));
  const addSseInfoRedux = (sseInfo) => dispatch(addsseInfo(sseInfo));
  const addYearAssRedux = (yearAss) => dispatch(addYearAss(yearAss));
  const addasseNameOrContactSelectedRedux = (asseNameOrContactSelected) => dispatch(addasseNameOrContactSelected(asseNameOrContactSelected));
  const addnewContactSelectedRedux = (newContactSelected) => dispatch(addnewContactSelected(newContactSelected));

  const addqsnListRedux = (qsnList) => dispatch(addqsnList(qsnList));
  const addqsnListLevelTwoRedux = (qsnListLevelTwo) => dispatch(addqsnListLevelTwo(qsnListLevelTwo));
  const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));

  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [ editItemIs, seteditItemIs ] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_edit')));
  // const [ forSelf, setforSelf ] = React.useState(editItemIs.self_assessment);

  const [ forSelf, setforSelf ] = React.useState();
  // const [ forSelf, setforSelf ] = React.useState("true");

  const [ superCategoryList, setsuperCategoryList ] = React.useState([]);
  const [ superCategoryValue, setsuperCategoryValue ] = React.useState("");
  const [ subCategoryList, setsubCategoryList ] = React.useState([]);
  const [ SubCtegoryFetched, setSubCtegoryFetched ] = React.useState(false);
  const [ subCategoryValue, setsubCategoryValue ] = React.useState("");
  const [ SelectedCategoryIsCategory, setSelectedCategoryIsCategory ] = React.useState(false);
  const [ categoryDetailsLastList, setcategoryDetailsLastList ] = React.useState([]);

  const [ lastCategoryValue, setlastCategoryValue ] = React.useState();
  // const [ lastCategoryValue, setlastCategoryValue ] = React.useState("0");

  const [selectedAssesmentYear, setselectedAssesmentYear] = React.useState("");

  // const [ SSEInfo, setSSEInfo] = React.useState({});
  const [ SSEInfo, setSSEInfo] = React.useState({
    created_by: "client",
    assesse_name: "",
    assesse_id: "",
    assesse_email: "",
    assesse_pan: "",
    assesse_phone: "",
    assesse_country_code: "",
    self_assessment: "",
    assessment_year: "",
    service_id: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [ClientContactDetails, setClientContactDetails] = React.useState([])
  const [AddNewContactFlag, setAddNewContactFlag] = React.useState()
  const [AssenameOrOtherContactSelected, setAssenameOrOtherContactSelected] = React.useState()

  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const [CountryCode, setCountryCode] = React.useState("");

  const [ClientIsRegisteredOrNot, setClientIsRegisteredOrNot] = React.useState("");

 const [flag , setflag] = React.useState(false);

 const [AssesmentYearArray, setAssesmentYearArray] = React.useState([])


 let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  console.log("user_permissions from local storage on header page::::::",user_permissions)

  const getAssesmentYear = () => {
    var year1 = new Date();
    var pastYear = year1.getFullYear() - 10;
    year1.setFullYear(pastYear);
    console.log("d== d1.getFullYear()",year1, year1.getFullYear());
    var year1Final = year1.getFullYear()

    var year2 = new Date();
    var pastYear = year2.getFullYear() + 2;
    year2.setFullYear(pastYear);
    console.log("d== d1.getFullYear()",year2, year2.getFullYear());
    var year2Final = year2.getFullYear()

    const value = [{value:"N/A", item:"N/A"}];
    for (let i = year1Final ; i <= year2Final ; i++) {

      const last2 = i.toString().slice(-2);
      const lastis = parseInt(last2)+1;
      console.log("last2 DIGITS ARE",last2);
      console.log("lastis DIGITS ARE",lastis);

      let LastValueIs = 'AY' + '-' + i.toString() + '-' +  lastis

      console.log("LastValueIs of assesment year", LastValueIs)

      // let a = {value:i.toString(), item:i.toString()}
      let a = {value:LastValueIs.toString(), item:LastValueIs.toString()}
      
      value.push(a);
      // AssesmentYearArray.push(a)
      console.log("value array is", value)
    }
    console.log("value array is", value)
    setAssesmentYearArray({...AssesmentYearArray, value })
  }

  useEffect(() => {
    setflag(false);
    // readItemFromStorage();
    setShowProcedure(true);
    // let editItemIs = JSON.parse(localStorage.getItem('selected_case_for_edit'))
    // if( editItemIs == null){
    //   console.log("editItemIs is null",editItemIs)
    // }
    // else if( editItemIs !== null){
    //   console.log("editItemIs is not null",editItemIs)
    // }
    // console.log("SSE INFO IN USE EFFECT",SSEInfo)
    // onSelectService("1","",forSelf,SSEInfo)

    getAssesmentYear()


    // to set value from redux
    if(selfAssesFromRedux !== undefined ){
      console.log("selfAssesFromRedux in useeffect",selfAssesFromRedux)
      setforSelf(selfAssesFromRedux)
      setShowProcedure(false);
    }
    if(superCategoryFromRedux !== undefined){
      setsuperCategoryValue(superCategoryFromRedux)
    }
    if(subCategoryFromRedux !== undefined){
      setsubCategoryValue(subCategoryFromRedux)
    }
    if(subCategoryFetchedFromRedux !== undefined){
      setSubCtegoryFetched(subCategoryFetchedFromRedux)
    }
    if(selectedCategoryFlagFromRedux !== undefined){
      setSelectedCategoryIsCategory(selectedCategoryFlagFromRedux)
    }
    if(lastCategoryFromRedux !== undefined){
      setlastCategoryValue(lastCategoryFromRedux)
    }
    if(sseInfoFromRedux !== undefined){
      setSSEInfo(sseInfoFromRedux)
      console.log("sseInfoFromRedux if condi", )
    }
    if(yearAssFromRedux !== undefined){
      setselectedAssesmentYear(yearAssFromRedux)
    }
    if(asseNameOrContactSelectedFromRedux !== undefined){
      setAssenameOrOtherContactSelected(asseNameOrContactSelectedFromRedux)
    }
    if(newContactSelectedFromRedux !== undefined){
      setAddNewContactFlag(newContactSelectedFromRedux)
    }



    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let category_type = "supercategory"

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        setShowProcedure(false);
        console.log("lastCategoryValue,forSelf,CountryCode,selectedAssesmentYear",lastCategoryValue,forSelf,CountryCode,selectedAssesmentYear)
        onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
        console.log("super categoryDetails for category list",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          // let catListHere = superCategoryDetails.data.categories.filter(item => item.is_live == true )
          setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true ))
          // setsuperCategoryList(superCategoryDetails.data.categories)

        }
        else
        {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      if(superCategoryFromRedux !== undefined){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'parent_category_id': superCategoryFromRedux,
          'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
          .then((Response) => Response.json())
          .then( subCategoryDetails => {
            console.log("subCategoryDetails for listnin useEffect",subCategoryDetails.data.category_children);
            if(subCategoryDetails.data.category_children)
            {
              // setsubCategoryList(subCategoryDetails.data.category_children)
              setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
            }
            else
            {
              setsubCategoryList([])
            }
            setSubCtegoryFetched(true)
            addSubCategoryFetchedRedux(true)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }

      if(subCategoryFromRedux !== undefined){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'parent_category_id': subCategoryFromRedux,
          'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
          .then((Response) => Response.json())
          .then( categoryLastDetails => {
            console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
            if(categoryLastDetails.data.category_children)
            {
              // setcategoryDetailsLastList(categoryLastDetails.data.category_children)
              setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
            }
            else
            {
              setcategoryDetailsLastList([])
            }
            setSubCtegoryFetched(true)
            addSubCategoryFetchedRedux(true)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }

    // to get client contacts
    let user_details = JSON.parse(localStorage.getItem('users'));
    console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id)
    let client_id = user_details.data.user_details.business_link.client_id
    const requestOptionsClientContacts = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'client_id': client_id,
      'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
    };
    fetch( process.env.REACT_APP_GET_CLIENT_CONTACTS , requestOptionsClientContacts)
    .then((Response) => Response.json())
    .then( clientContactDetails => {
      if( clientContactDetails.success == true){
        if(clientContactDetails.data.contacts_info)
        {
          console.log("clientContactDetails for list",clientContactDetails.data.contacts_info.contacts);
          setClientContactDetails(clientContactDetails.data.contacts_info.contacts)
          setClientIsRegisteredOrNot("Yes")
        }
      }
      if( clientContactDetails.success == false){
        setClientIsRegisteredOrNot("No")
        Swal.fire({
          // icon: 'error',
          text: clientContactDetails.errors,
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK'
        })
        setClientContactDetails([])
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  }, [flag]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)
  //   } catch (error) {
  //       console.log("ERROR:",error);
  //   }
  // };

  const fillingForursefChange = async(e) => {
    console.log("e of fillingForursefChange",e.target.value)
    // console.log("event.target.checked=====",e.target.checked);
    let selfAssSelected = e.target.value
    // let selfAssSelected = JSON.parse(e.target.value)
    await setforSelf(selfAssSelected);
    await addSelfAssRedux(selfAssSelected);
    // addSseInfoRedux({...SSEInfo, self_assessment: JSON.parse(e.target.value) })
    if( selfAssSelected == "false" || selfAssSelected == false){
      await addSseInfoRedux({ ...SSEInfo,
        assesse_name: "",
        assesse_id:  "",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
        self_assessment: "",
        assessment_year: "",
        service_id: "",
      })
    }
    // await setSSEInfo({...SSEInfo, self_assessment: JSON.parse(selfAssSelected) })
    setselectedAssesmentYear();
    addYearAssRedux();
    setsuperCategoryValue("");
    addSuperCategoryRedux("");
    setsubCategoryValue("");
    addSubCategoryRedux("");
    setSubCtegoryFetched(false);
    addSubCategoryFetchedRedux(false);
    // setsubCategoryList([]);
    setSelectedCategoryIsCategory(false);
    addSelectedCategoryFlagRedux(false);
    // setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    addLastCategoryRedux("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addasseNameOrContactSelectedRedux ();
    addnewContactSelectedRedux();
    setAddNewContactFlag();
    setAssenameOrOtherContactSelected();
    setSSEInfo({
      created_by: "client",
      assesse_name: "",
      assesse_id: "",
      assesse_email: "",
      assesse_pan: "",
      assesse_phone: "",
      assesse_country_code: "",
      self_assessment: JSON.parse(selfAssSelected),
      assessment_year: "",
      service_id: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
  }

  const handleChangeSelectOtherContact = async(e) => {
    console.log("handleChangeSelectOtherContact for client",e.target.value)
    let valueHere = e.target.value
    if( valueHere == "Add New Contact"){
      console.log("in handl eChangeSelectOtherContact in == Add New Contact",valueHere)
      await setAddNewContactFlag(true);
      await setAssenameOrOtherContactSelected(true);
      await addasseNameOrContactSelectedRedux (true);
      await addnewContactSelectedRedux(true);
      // await setnewCaseDetails({...newCaseDetails, assesse_id:"" })
      // await setSSEInfo({...SSEInfo, assesse_id: "" })
      await setSSEInfo({...SSEInfo, assesse_id: "" ,
        assesse_name:"",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
      })
      // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
    }
    else if(valueHere == ""){
      console.log("in handle ChangeSelectOtherContact in == blank ",valueHere)
      await setAddNewContactFlag(false);
      await setAssenameOrOtherContactSelected(false);
      await addasseNameOrContactSelectedRedux (false);
      await addnewContactSelectedRedux(false);
      // await setSSEInfo({...SSEInfo, assesse_id: "" })
      await setSSEInfo({...SSEInfo, assesse_id: "" ,
        assesse_name:"",
        assesse_email: "",
        assesse_pan: "",
        assesse_phone: "",
        assesse_country_code: "",
      })
      // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
    }
    else if(valueHere !== ""){
      console.log("in handle ChangeSelectOtherContact in !== blank ",valueHere)
      await setAddNewContactFlag(false);
      await setAssenameOrOtherContactSelected(true);
      await addasseNameOrContactSelectedRedux (true);
      await addnewContactSelectedRedux(false);
      
      // await setSSEInfo({...SSEInfo, assesse_id: valueHere })
      // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
      ClientContactDetails && ClientContactDetails.map( async(item) =>{
        console.log("ClientContactDetails map in drop",item)
        if( item.assesse_id == valueHere){
          console.log("item.assesse_id == valueHere",item)
          await setSSEInfo({...SSEInfo, assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
            
          })
          await addSseInfoRedux({...SSEInfo, assesse_name:item.name,
            assesse_email: item.email,
            assesse_pan: item.pan,
            assesse_phone: item.phone,
            assesse_country_code: item.assesse_country_code,
            assesse_id: item.assesse_id,
            
          })
        }
      })
    }
  }

  const handleChangeSuperAcategory = async(e) => {
    console.log("e of super category",e.target.value)
    let superCategoryIs = e.target.value
    await setsuperCategoryValue(Number(superCategoryIs));
    await addSuperCategoryRedux(Number(superCategoryIs));
    await setsubCategoryValue("");
    await addSubCategoryRedux("");
    setSelectedCategoryIsCategory(false);
    addSelectedCategoryFlagRedux(false);
    // setcategoryDetailsLastList([]);
    setlastCategoryValue("")
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    addLastCategoryRedux("")

    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': superCategoryIs,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          // setsubCategoryList(subCategoryDetails.data.category_children)
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
        addSubCategoryFetchedRedux(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    // let serviceIdFinalIs = JSON.parse(e.target.value)
    console.log("e of handleChangeSubcategory",e.target.value)
    // console.log("e of catType",catType)

    // 4 may
    if( serviceIdFinalIs == ""){
      await setSelectedCategoryIsCategory(false)
      await setlastCategoryValue()
      await addLastCategoryRedux()
      await addSubCategoryRedux("");
      await setsubCategoryValue("");
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
      // await onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    }
    // 13 may
    else{
      await addSubCategoryRedux(Number(serviceIdFinalIs));
      await setsubCategoryValue(Number(serviceIdFinalIs));
      //
      await setlastCategoryValue(serviceIdFinalIs)
      await addLastCategoryRedux(serviceIdFinalIs)
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
      // await onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    }
    

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(serviceIdFinalIs == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)

    // if( catType == "service" ){
    if(matchedCategory.category_type == "service"){
      setSelectedCategoryIsCategory(false)
      addSelectedCategoryFlagRedux(false)
      console.log("service is selected now navigate to question set")
      onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      console.log('serviceID is subCategoryValue', serviceIdFinalIs)
    }
    // else if( catType == "category" ){
    else if( matchedCategory.category_type == "category" ){
      onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      setSelectedCategoryIsCategory(true)
      addSelectedCategoryFlagRedux(true)

      // 4 may
      await setlastCategoryValue()
      await addLastCategoryRedux()
      // 4 may

      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': serviceIdFinalIs,
        'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
        .then((Response) => Response.json())
        .then( categoryLastDetails => {
          console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
          if(categoryLastDetails.data.category_children)
          {
            // setcategoryDetailsLastList(categoryLastDetails.data.category_children)
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
          }
          else
          {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true);
          addSubCategoryFetchedRedux(true);
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
  }

  const handleChangeLastCategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category",e.target.value)

    if ( serviceIdFinalIs == "" ){
      await addLastCategoryRedux("")
      await setlastCategoryValue("");
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
    }
    else{
      await addLastCategoryRedux(Number(serviceIdFinalIs))
      await setlastCategoryValue(Number(serviceIdFinalIs));
      await addqsnListRedux()
      await addqsnListLevelTwoRedux()
      await addqsnListLevelThreeRedux()
    }

    console.log("last category service is selected now navigate to question set")
    onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear);
    console.log('serviceID is lastCategoryValue', serviceIdFinalIs);
  }

  // const HandleChangeAssesmentYear = async(date) => {
  const HandleChangeAssesmentYear = async(e) => {

    // console.log("in handleChangeAssesment Year", date)
    // console.log("date.getFullYear()",JSON.stringify(date.getFullYear()))
    // let assesmentYearHere = JSON.stringify(date.getFullYear())
    // await setselectedAssesmentYear(assesmentYearHere);
    // await addYearAssRedux(assesmentYearHere);
    // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,assesmentYearHere);


    // dropdown from this toi
    console.log("e HandleChangeAssesmentYear",e.target.value)
    let assesmentYearIs = e.target.value
    await setselectedAssesmentYear(assesmentYearIs);
    await addYearAssRedux(assesmentYearIs);

    onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,assesmentYearIs);
    // dropdown this
  }

  const handleChangeSSEFormInfo = async(e) => {

    console.log("handle Change SSE Form Info e.target",e.target.value, e.target.name);
    const { name, value } = e.target;

    if ( e.target.name == "assesse_pan" ){
      await addSseInfoRedux({...SSEInfo, [name]:value.toUpperCase() })
      await setSSEInfo({...SSEInfo, [name]:value.toUpperCase() })
    }
    else{
      await addSseInfoRedux({...SSEInfo, [name]:value })
      await setSSEInfo({...SSEInfo, [name]:value })
    }
    // console.log("SSEInfo",SSEInfo)
    // if( name == "assesse_name"){
    //   addSseInfoRedux({...SSEInfo, assesse_name:e.target.value  })
    //   await setSSEInfo({...SSEInfo, assesse_name:e.target.value  })

    // }

    // setflag(false);
    // if( forSelf == false ){
    // if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
      console.log("in if condition of checking all values",sseInfoFromRedux )
      // onSelectService("1","",forSelf,SSEInfo)
      onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // }
    // }

  }

  const assessephoneNumberChange = async(assessePhoneNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange assessePhoneNumber",assessePhoneNumber)
      console.log("phoneNumberChange e",e)
      console.log("phoneNumberChange value",value)
      let countryCodeIs = "+" + e.dialCode
      let splitNoIs = ""
      if(e.countryCode == "in"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "us"){
        //  splitNoIs = value.split(" ")[1];

        // this is showing 1st digit before white space
        // splitNoIs = value.substr(0,value.indexOf(' '));

        // this is showing digits after white space
        // splitNoIs = value.substr(value.indexOf(' ')+1);

        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "gb"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ca"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ae"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "sg"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "np"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "jp"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "de"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "bt"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "au"){
        splitNoIs = value.split(" ")[1];
      }

      console.log("splitNoIs",splitNoIs)
      let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
      console.log("noHereIs",noHereIs)
      setCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
      // await addSseInfoRedux({...SSEInfo, assesse_phone:noHereIs })
      // await setSSEInfo({...SSEInfo, assesse_phone:noHereIs })
      // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
      await addSseInfoRedux({...SSEInfo, assesse_phone: splitNoIs , assesse_country_code:countryCodeIs })
      await setSSEInfo({...SSEInfo, assesse_phone: splitNoIs, assesse_country_code:countryCodeIs })
      onSelectService("1",lastCategoryValue,forSelf,SSEInfo,e.countryCode,selectedAssesmentYear)
  }

  const handleChangeSuperAcategoryForSelfFalse = async(e) => {
    console.log("e of super category",e.target.value)
    let superCategoryForSelf = e.target.value
    await setsuperCategoryValue(Number(superCategoryForSelf));
    await addSuperCategoryRedux(Number(superCategoryForSelf));
    await setlastCategoryValue(Number(superCategoryForSelf))
    await addLastCategoryRedux(Number(superCategoryForSelf))
    addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    setsubCategoryValue("");
    addSubCategoryRedux("");
    setSelectedCategoryIsCategory(false);
    addSelectedCategoryFlagRedux(false)
    setcategoryDetailsLastList([]);
    // setlastCategoryValue("")
    // addLastCategoryRedux("")

    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': superCategoryForSelf,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          // setsubCategoryList(subCategoryDetails.data.category_children)
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
        addSubCategoryFetchedRedux(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategoryForSelfFalse = async(e) => {
    let serviceIdFinalIs = e.target.value
    // let serviceIdFinalIs = JSON.parse(e.target.value)
    console.log("e of handleChangeSubcategoryForSelfFalse",e.target.value)
    // console.log("e of catType",catType)
    let service_is = Number(serviceIdFinalIs) ;
    console.log("e of service_is",service_is)
    await addSubCategoryRedux(Number(serviceIdFinalIs))
    await setsubCategoryValue(Number(serviceIdFinalIs));
    await setlastCategoryValue(Number(serviceIdFinalIs))
    await addLastCategoryRedux(Number(serviceIdFinalIs))
    await addqsnListRedux()
    addqsnListLevelTwoRedux()
    addqsnListLevelThreeRedux()
    // setlastCategoryValue("")
    // addLastCategoryRedux("")

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(serviceIdFinalIs == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory catType",matchedCategory)

    // if( catType == "service" ){
    if(matchedCategory.category_type == "service"){
      setSelectedCategoryIsCategory(false)
      addSelectedCategoryFlagRedux(false)
      console.log("service is selected now navigate to question set")
      await addSseInfoRedux({...SSEInfo, service_id: JSON.parse(serviceIdFinalIs) })
      await setSSEInfo({...SSEInfo, service_id: serviceIdFinalIs })
      // addSseInfoRedux(SSEInfo)
      // if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
        console.log("in if condition of checking all values",SSEInfo)
        await onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
      // }
      console.log('serviceID is subCategoryValue', serviceIdFinalIs,SSEInfo,sseInfoFromRedux)
    }
    // else if( catType == "category" ){
    else if( matchedCategory.category_type == "category" ){
      setSelectedCategoryIsCategory(true)
      addSelectedCategoryFlagRedux(true)
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': serviceIdFinalIs,
        'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
        .then((Response) => Response.json())
        .then( categoryLastDetails => {
          console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
          if(categoryLastDetails.data.category_children)
          {
            // setcategoryDetailsLastList(categoryLastDetails.data.category_children)
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
          }
          else
          {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true)
          addSubCategoryFetchedRedux(true)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
  }

  const handleChangeLastCategoryForSelfFalse = async(e) => {
    let serviceIdFinalIs = e.target.value
    // let serviceIdFinalIs = JSON.parse(e.target.value)
    console.log("e LastCategory category",e.target.value)
    let service_is = Number(serviceIdFinalIs) ;
    console.log("e of service_is",service_is)

    // 13 may
    if( serviceIdFinalIs == "" ){
      addLastCategoryRedux("")
      addqsnListRedux()
      addqsnListLevelTwoRedux()
      addqsnListLevelThreeRedux()
      await setlastCategoryValue("");
      await addSseInfoRedux({...SSEInfo, service_id: "" })
      await setSSEInfo({...SSEInfo, service_id: "" })
    }
    else{
      addLastCategoryRedux(Number(serviceIdFinalIs))
      addqsnListRedux()
      addqsnListLevelTwoRedux()
      addqsnListLevelThreeRedux()
      await setlastCategoryValue(Number(serviceIdFinalIs));
      await addSseInfoRedux({...SSEInfo, service_id: JSON.parse(serviceIdFinalIs) })
      await setSSEInfo({...SSEInfo, service_id: serviceIdFinalIs })
    }

    // addSseInfoRedux(SSEInfo)
    console.log("last category service is selected now navigate to question set",SSEInfo)
    // if( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan ){
      console.log("in if condition of checking all values",SSEInfo)
      onSelectService("1",serviceIdFinalIs,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // }
    console.log('serviceID is lastCategoryValue', serviceIdFinalIs);
  }

  // const handleChangeAssesmentYearForSelfFalse = async(date) => {
  const handleChangeAssesmentYearForSelfFalse = async(e) => {

    // console.log("in handleChangeAssesment Year", date)
    // console.log("date.getFullYear()",JSON.stringify(date.getFullYear()))
    // let assesmentYearHere = JSON.stringify(date.getFullYear())
    // await addSseInfoRedux({...SSEInfo, assessment_year: assesmentYearHere })
    // await setSSEInfo({...SSEInfo, assessment_year: assesmentYearHere })
    // console.log("in handleChange AssesmentYearForSelfFalse",SSEInfo)
    // onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)

    // dropdown from this to
    let assesmentYearIs = e.target.value ;
    console.log("handleChange AssesmentYearForSelfFalse",e.target.value, assesmentYearIs)

    await addSseInfoRedux({...SSEInfo, assessment_year: assesmentYearIs })
    await setSSEInfo({...SSEInfo, assessment_year: assesmentYearIs })

    console.log("in handleChange AssesmentYearForSelfFalse",SSEInfo)
    onSelectService("1",lastCategoryValue,forSelf,SSEInfo,CountryCode,selectedAssesmentYear)
    // to this
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  return (
    console.log("SSEInfo,sseInfoFromRedux on client gen ",SSEInfo,sseInfoFromRedux),
    console.log("ClientContactDetails in ruturn==ß",ClientContactDetails),
    // addSseInfoRedux({...SSEInfo, SSEInfo }),
    // console.log("selfAssesFromRedux, sseInfo from redux ",selfAssesFromRedux, sseInfoFromRedux ),
    <div className={classes.root}>
      {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </Modal>: null}
      <div className={classes.wrapper}>
        <Grid container>
          {/* {
            (editItemIs == null)?
            (  */}
              <Grid container>
              <Grid item xs={12} className={classes.subHeadingLabel}>
                {/* Are you filling for Yourself?? */}
                Are you creating a case for yourselves?
              </Grid>
              <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue={selfAssesFromRedux}
                  // onChange={(event, value) => setforSelf(value) }
                  onChange={fillingForursefChange}
                >
                  <div className={classes.customRadioButton}>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div className={classes.customRadioButton}>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                      disabled = { ClientIsRegisteredOrNot == "No" ? true : false}
                    />
                  </div>
              </RadioGroup>
                <Grid>
                  {
                    ( ClientIsRegisteredOrNot == "No" )?
                    (
                      <Grid style={{ color:"grey" , fontSize:'12px', marginLeft:'-10%'}}>
                        *Option will enable once client is registered
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                </Grid>
              </Grid>
          {/* //   ):
          //   ( console.log("edit item is not null "))
          // } */}

          {
            ( forSelf == "false" || forSelf == false)?
            (
              <Grid container>
                <Grid item xs={12} style={{marginTop:'3%'}}>
                  <FormControl variant="outlined" className={classes.formControlOtherContact}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={SSEInfo.assesse_id}
                      autoFocus
                      onChange = {handleChangeSelectOtherContact}
                    >
                      <option value="" style={{color:'grey'}}>Select Other Contact</option>
                      {
                        (role_of_user && role_of_user == "client" )?
                        (
                          <option value="Add New Contact" style={{color:'blue'}}>Add New Contact</option>
                        ):
                        (
                          (role_of_user && role_of_user !== "client")?
                          (
                            (user_permissions && user_permissions.permissions.add_contacts  === true )?
                            (
                              <option value="Add New Contact" style={{color:'blue'}}>Add New Contact</option>
                            ):
                            (
                              <div></div>
                            )
                          ):
                          (
                            <div></div>
                          )
                        )
                      }
                          {ClientContactDetails && ClientContactDetails.map(item =>
                            <option value={item.assesse_id}>{item.name}</option>
                          )}
                    </Select>
                  </FormControl>
                </Grid>

                {
                  ( AssenameOrOtherContactSelected == true )?
                  (
                    <Grid item xs={12}>
                      {
                        ( AddNewContactFlag == true) ?
                        (
                          <Grid item xs={12}>
                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              Tax Payer Name
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                // id="assesse_name"
                                name="assesse_name"
                                value={SSEInfo.assesse_name}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            <div className={classes.validation} style={{textAlign:'left'}}>{sseNameErr}</div>

                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              Tax Payer Email
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="assesse_email"
                                value={SSEInfo.assesse_email}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            <div className={classes.validation} style={{textAlign:'left'}}>{sseEmailErr}</div>

                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              Tax Payer Phone Number
                            </Grid>
                            <PhoneInput
                              inputStyle={{
                                width: "100%",
                                height: "50px",
                                fontSize: "15px",
                                autoFocus:true
                              }}
                              countryCodeEditable={false}
                              onlyCountries={onlyCountryArray}
                              specialLabel=""
                              country={'in'}
                              masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                                sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                                au: '.............'  }}
                              // value={SSEInfo.assesse_phone}
                              value={SSEInfo.assesse_country_code + SSEInfo.assesse_phone}
                              onChange={(assessePhoneNumber,e,formattedValue,value) => assessephoneNumberChange(assessePhoneNumber,e,formattedValue,value)}
                            />
                            <div className={classes.validation} style={{textAlign:'left'}}>{ssePhoneNoErr}</div>

                            <Grid item xs={12} className={classes.subHeadingLabel}>
                              Tax Payer PAN
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                fullWidth
                                // id="assesse_pan"
                                name="assesse_pan"
                                placeholder="XXXXX0000X"
                                inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                                value={SSEInfo.assesse_pan}
                                onChange = {handleChangeSSEFormInfo}
                              />
                            </Grid>
                            <div className={classes.validation} style={{textAlign:'left'}}>{ssePanErr}</div>

                              {/* first super category options */}
                              {/* { */}
                                {/* // ( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan )?
                                // ( */}
                                <Grid container item xs={12}>
                                  <Grid item xs={3} style={{marginTop:'2%'}}>
                                    <Grid className={classes.subHeadingLabelSelect}>
                                      {/* <FormattedMessage id="caseCategoryLabel" /> */}
                                      Super Catgory *
                                    </Grid>
                                    <Grid>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={superCategoryValue}
                                            defaultValue={superCategoryFromRedux}
                                            onChange = {handleChangeSuperAcategoryForSelfFalse}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Super Category</option>
                                              {superCategoryList && superCategoryList.map(item =>
                                              <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                      {/* <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div> */}
                                      <div className={classes.validation} style={{textAlign:'left'}}>
                                        {
                                          ( sseServiceErr )?
                                          (
                                            <Grid>
                                              {
                                                (superCategoryValue) ? (<div></div>):(
                                                  <div>Please select super category </div>
                                                )
                                              }
                                            </Grid>
                                          ):
                                          (
                                            <Grid></Grid>
                                          )
                                        }
                                      </div> 
                                    </Grid>
                                  </Grid>

                                    {/* // ):(
                                    //   <div></div>
                                    // )
                                  } */}

                                  {
                                    ( SubCtegoryFetched == true )?
                                      (
                                        <Grid item xs={3} style={{marginTop:'2%'}} >
                                          <Grid className={classes.subHeadingLabelSelect}>
                                            Category or Service *
                                          </Grid>
                                          <Grid>
                                          <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                              native
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={subCategoryValue}
                                              defaultValue={subCategoryFromRedux}
                                              onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                            >
                                              <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                                {subCategoryList && subCategoryList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                              )}
                                            </Select>
                                          </FormControl>
                                          <div className={classes.validation}>
                                            {
                                              ( sseServiceErr )?
                                              (
                                                <Grid>
                                                  {
                                                    (subCategoryValue) ? (<div></div>):(
                                                      <div>Please select category or service</div>
                                                    )
                                                  }
                                                </Grid>
                                              ):
                                              (
                                                <Grid></Grid>
                                              )
                                            }
                                          </div> 
                                          </Grid>
                                        </Grid>
                                      ):
                                      (<div></div>)
                                    }

                                    {
                                      ( SelectedCategoryIsCategory == true)?
                                      (
                                        <Grid item xs={3} style={{marginTop:'2%'}} >
                                          <Grid className={classes.subHeadingLabelSelect}>
                                            {/* Case Category */}
                                            Service *
                                          </Grid>
                                          <Grid >
                                            <FormControl variant="outlined" className={classes.formControl}>
                                              <Select
                                                native
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={lastCategoryValue}
                                                defaultValue={lastCategoryFromRedux}
                                                onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                              >
                                                <option value="" style={{color:'grey'}}>Select Service</option>
                                                  {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                  <option value={item.category_id}>{item.name}</option>
                                                  )}
                                              </Select>
                                            </FormControl>
                                            <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div>
                                            
                                          </Grid>
                                        </Grid>
                                      ):
                                      (<div></div>)
                                    }
                                </Grid>

                                <Grid item xs={3} style={{marginTop:'2%'}}>
                                  <Grid className={classes.subHeadingLabelSelect}>
                                    Assesment Year
                                  </Grid>
                                  {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                                    <DatePicker
                                      selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                      defaultValue={SSEInfo.assessment_year}
                                      onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                      placeholderText="Select Year"
                                      showYearPicker
                                      dateFormat="yyyy"
                                      yearItemNumber={8}
                                      minDate={minDate}
                                      maxDate={dueDate}
                                      className={classes.datePickerStyle}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  </Grid> */}
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                      native
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={SSEInfo.assessment_year}
                                      defaultValue={SSEInfo.assessment_year}
                                      // defaultValue={sseInfoFromRedux.assessment_year}
                                      // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                      onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                    >
                                      <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                      {AssesmentYearArray.value && AssesmentYearArray.value.map(item =>
                                        <option value={item.value}>{item.item}</option>
                                      )}
                                      {/* <option value="AY-2014-15" >AY-2014-15</option>
                                      <option value="AY-2015-16" >AY-2015-16</option>
                                      <option value="AY-2016-17" >AY-2016-17</option>
                                      <option value="AY-2017-18" >AY-2017-18</option>
                                      <option value="AY-2018-19" >AY-2018-19</option>
                                      <option value="AY-2019-20" >AY-2019-20</option>
                                      <option value="AY-2020-21" >AY-2020-21</option>
                                      <option value="AY-2021-22" >AY-2021-22</option>
                                      <option value="AY-2022-23" >AY-2022-23</option>
                                      <option value="AY-2023-24" >AY-2023-24</option>
                                      <option value="AY-2024-25" >AY-2024-25</option>
                                      <option value="N/A" >N/A</option> */}
                                    </Select>
                                  </FormControl>
                                  <div className={classes.validation} style={{textAlign:'left'}}>{(SSEInfo.assessment_year)?(<div></div>):(asseAssessmentYearErr)}</div>
                                </Grid>

                          </Grid>
                        )
                        :(
                          <Grid>
                            {/* first super category options */}
                            {/* { */}
                              {/* // ( SSEInfo.assesse_name && SSEInfo.assesse_email && SSEInfo.assesse_phone && SSEInfo.assesse_pan )?
                              // ( */}
                              <Grid container item xs={12}>
                                <Grid item xs={3} style={{marginTop:'2%'}}>
                                  <Grid className={classes.subHeadingLabelSelect}>
                                    {/* <FormattedMessage id="caseCategoryLabel" /> */}
                                    Super Catgory *----
                                  </Grid>
                                  <Grid>
                                      <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                          native
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={superCategoryValue}
                                          defaultValue={superCategoryFromRedux}
                                          onChange = {handleChangeSuperAcategoryForSelfFalse}
                                        >
                                          <option value="" style={{color:'grey'}}>Select Super Category</option>
                                            {superCategoryList && superCategoryList.map(item =>
                                            <option value={item.category_id}>{item.name}</option>
                                          )}
                                        </Select>
                                      </FormControl>
                                    {/* <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div> */}
                                    <div className={classes.validation}>
                                      {
                                        ( sseServiceErr )?
                                        (
                                          <Grid>
                                            {
                                              (superCategoryValue) ? (<div></div>):(
                                                <div>Please select super category </div>
                                              )
                                            }
                                          </Grid>
                                        ):
                                        (
                                          <Grid></Grid>
                                        )
                                      } 
                                    </div>
                                  </Grid>
                                </Grid>

                                  {/* // ):(
                                  //   <div></div>
                                  // )
                                } */}

                                {
                                  ( SubCtegoryFetched == true )?
                                    (
                                      <Grid item xs={3} style={{marginTop:'2%'}} >
                                        <Grid className={classes.subHeadingLabelSelect}>
                                          Category or Service *
                                        </Grid>
                                        <Grid>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                          <Select
                                            native
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={subCategoryValue}
                                            defaultValue={subCategoryFromRedux}
                                            onChange={(e) => handleChangeSubcategoryForSelfFalse(e)}
                                          >
                                            <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                              {subCategoryList && subCategoryList.map(item =>
                                              <option value={item.category_id}>{item.name}</option>
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div className={classes.validation}>
                                          {
                                            ( sseServiceErr )?
                                            (
                                              <Grid>
                                                {
                                                  (subCategoryValue) ? (<div></div>):(
                                                    <div>Please select category or service </div>
                                                  )
                                                }
                                              </Grid>
                                            ):
                                            (
                                              <Grid></Grid>
                                            )
                                          } 
                                        </div>
                                        </Grid>
                                      </Grid>
                                    ):
                                    (<div></div>)
                                  }

                                  {
                                    ( SelectedCategoryIsCategory == true)?
                                    (
                                      <Grid item xs={3} style={{marginTop:'2%'}} >
                                        <Grid className={classes.subHeadingLabelSelect}>
                                          {/* Case Category */}
                                          Service *
                                        </Grid>
                                        <Grid >
                                          <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                              native
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={lastCategoryValue}
                                              defaultValue={lastCategoryFromRedux}
                                              onChange={(e) => handleChangeLastCategoryForSelfFalse(e)}
                                            >
                                              <option value="" style={{color:'grey'}}>Select Service</option>
                                                {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                                <option value={item.category_id}>{item.name}</option>
                                                )}
                                            </Select>
                                          </FormControl>
                                          <div className={classes.validation}>{(SSEInfo.service_id)?(<div></div>):(sseServiceErr)}</div>
                                        </Grid>
                                      </Grid>
                                    ):
                                    (<div></div>)
                                  }
                              </Grid>

                              <Grid item xs={3} style={{marginTop:'2%'}}>
                                <Grid className={classes.subHeadingLabelSelect}>
                                  Assesment Year
                                </Grid>
                                {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                                  <DatePicker
                                    selected={Date.parse(moment(SSEInfo.assessment_year, 'YYYY').toISOString())}
                                    defaultValue={SSEInfo.assessment_year}
                                    onChange={(date) => handleChangeAssesmentYearForSelfFalse(date)}
                                    placeholderText="Select Year"
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={8}
                                    minDate={minDate}
                                    maxDate={dueDate}
                                    className={classes.datePickerStyle}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Grid> */}
                                <FormControl variant="outlined" className={classes.formControl}>
                                  <Select
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={SSEInfo.assessment_year}
                                    defaultValue={SSEInfo.assessment_year}
                                    // defaultValue={sseInfoFromRedux.assessment_year}
                                    // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                                    onChange={(e) => handleChangeAssesmentYearForSelfFalse(e) }
                                  >
                                    <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                                    {AssesmentYearArray.value && AssesmentYearArray.value.map(item =>
                                      <option value={item.value}>{item.item}</option>
                                    )}
                                    {/* <option value="AY-2014-15" >AY-2014-15</option>
                                    <option value="AY-2015-16" >AY-2015-16</option>
                                    <option value="AY-2016-17" >AY-2016-17</option>
                                    <option value="AY-2017-18" >AY-2017-18</option>
                                    <option value="AY-2018-19" >AY-2018-19</option>
                                    <option value="AY-2019-20" >AY-2019-20</option>
                                    <option value="AY-2020-21" >AY-2020-21</option>
                                    <option value="AY-2021-22" >AY-2021-22</option>
                                    <option value="AY-2022-23" >AY-2022-23</option>
                                    <option value="AY-2023-24" >AY-2023-24</option>
                                    <option value="AY-2024-25" >AY-2024-25</option>
                                    <option value="N/A" >N/A</option> */}
                                  </Select>
                                </FormControl>
                                <div className={classes.validation} style={{textAlign:'left'}}>{(SSEInfo.assessment_year)?(<div></div>):(asseAssessmentYearErr)}</div>
                              </Grid>
                          </Grid>
                        )
                      }
                    </Grid>
                  )
                  :(<Grid></Grid>)
                }
              </Grid>

            ):
            (
              ( forSelf == "true" || forSelf == true )?
              (
                <Grid  container item xs={12}>
                  <Grid container item xs={12}>
                    {/* first super category options */}
                    <Grid item xs={3} style={{marginTop:'2%'}}>
                      <Grid className={classes.subHeadingLabelSelect}>
                        {/* <FormattedMessage id="caseCategoryLabel" /> */}
                        Super Catgory *
                      </Grid>
                      <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select
                            native
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={superCategoryValue}
                            defaultValue={superCategoryFromRedux}
                            onChange = {handleChangeSuperAcategory}
                          >
                            <option value="" style={{color:'grey'}}>Select Super Category</option>
                            {superCategoryList && superCategoryList.map(item =>
                            <option value={item.category_id}>{item.name}</option>
                            )}
                          </Select>
                        </FormControl>
                        {/* <div className={classes.validation}>{(superCategoryValue)?(<div></div>):(serviceIdErr)}</div> */}
                        <div className={classes.validation}>
                        {
                          ( serviceIdErr )?
                          (
                            <Grid>
                            {
                              (superCategoryValue) ? (<div></div>):(
                                <div>Please select super category </div>
                              )
                            }
                            </Grid>
                          ):
                          (
                            <Grid></Grid>
                          )
                        }
                        </div>
                      </Grid>
                    </Grid>

                    {/* second sub category options */}
                    {
                      ( SubCtegoryFetched == true )?
                      (
                        <Grid item xs={3} style={{marginTop:'2%'}}>
                          <Grid className={classes.subHeadingLabelSelect}>
                            Category or Service *
                          </Grid>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              native
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={subCategoryValue}
                              defaultValue={subCategoryFromRedux}
                              onChange={(e) => handleChangeSubcategory(e)}
                            >
                              <option value="" style={{color:'grey'}}>Select Category or Service</option>
                              {subCategoryList && subCategoryList.map(item =>
                              <option value={item.category_id}>{item.name}</option>
                              )}
                            </Select>
                          </FormControl>
                          <div className={classes.validation}>
                          {
                            ( serviceIdErr )?
                            (
                              <Grid>
                              {
                                (subCategoryValue) ? (<div></div>):(
                                  <div>Please select category or service </div>
                                )
                              }
                              </Grid>
                            ):
                            (
                              <Grid></Grid>
                            )
                          }
                          </div>
                        </Grid>
                      ):
                      (<div></div>)
                    }

                    {/* Third if service navigate else if category then show options */}
                    {
                      ( SelectedCategoryIsCategory == true)?
                      (
                        <Grid item xs={3} style={{marginTop:'2%'}}>
                          <Grid className={classes.subHeadingLabelSelect}>
                            {/* Case Category */}
                            Service *
                          </Grid>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              native
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={lastCategoryValue}
                              defaultValue={lastCategoryFromRedux}
                              onChange={(e) => handleChangeLastCategory(e)}
                            >
                              <option value="" style={{color:'grey'}}>Select service</option>
                              {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                              <option value={item.category_id}>{item.name}</option>
                              )}
                            </Select>
                          </FormControl>
                          <div className={classes.validation}>
                          {
                            ( serviceIdErr )?
                            (
                              <Grid>
                              {
                                (lastCategoryValue) ? (<div></div>):(
                                  <div>Please select service </div>
                                )
                              }
                              </Grid>
                            ):
                            (
                              <Grid></Grid>
                            )
                          }
                          </div>
                        </Grid>
                      ):
                      (<div></div>)
                    }

                  </Grid>
                    <Grid item xs={3} style={{marginTop:'2%'}}>
                      <Grid className={classes.subHeadingLabelSelect}>
                        Assesment Year
                      </Grid>
                      {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                        <DatePicker
                          selected={Date.parse(moment(selectedAssesmentYear, 'YYYY').toISOString())}
                          onChange={(date) => HandleChangeAssesmentYear(date)}
                          placeholderText="Select Year"
                          showYearPicker
                          dateFormat="yyyy"
                          yearItemNumber={8}
                          minDate={minDate}
                          maxDate={dueDate}
                          className={classes.datePickerStyle}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Grid> */}
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                          native
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedAssesmentYear}
                          defaultValue={yearAssFromRedux}
                          // onChange={(e) => setselectedAssesmentYear(e.target.value) }
                          onChange={(e) => HandleChangeAssesmentYear(e) }
                        >
                          <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                          {AssesmentYearArray.value && AssesmentYearArray.value.map(item =>
                            <option value={item.value}>{item.item}</option>
                          )}
                          {/* <option value="AY-2014-15" >AY-2014-15</option>
                          <option value="AY-2015-16" >AY-2015-16</option>
                          <option value="AY-2016-17" >AY-2016-17</option>
                          <option value="AY-2017-18" >AY-2017-18</option>
                          <option value="AY-2018-19" >AY-2018-19</option>
                          <option value="AY-2019-20" >AY-2019-20</option>
                          <option value="AY-2020-21" >AY-2020-21</option>
                          <option value="AY-2021-22" >AY-2021-22</option>
                          <option value="AY-2022-23" >AY-2022-23</option>
                          <option value="AY-2023-24" >AY-2023-24</option>
                          <option value="AY-2024-25" >AY-2024-25</option>
                          <option value="N/A" >N/A</option> */}
                        </Select>
                      </FormControl>
                      <div className={classes.validation}>{(selectedAssesmentYear)?(<div></div>):(assesmentYearErr)}</div>
                    </Grid>
                  </Grid>
              ):
              (
              <div></div>
              )
            )
          }
        </Grid>
      </div>
    </div>
  );
}

export default injectIntl(NewCaseGeneralDetailsClientCases);
